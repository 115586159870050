import React from 'react'

import DefaultLayout from 'layouts/DefaultLayout'

const NotFoundPage: React.FC = () => {
  return (
    <DefaultLayout>
      <h1 style={{ textAlign: 'center' }}>Page could not be found</h1>
    </DefaultLayout>
  )
}

export default NotFoundPage
