import { useState } from 'react'

import useGetRoleRegistable from 'graphQL/useGetRoleRegistable'
import useQueryString from 'hooks/useQueryString'

import type { ServiceHook } from '../interface'
import type { Role, UserField } from 'graphQL/useGetRoleRegistable/interface'
import type { UseRoleOptions, UseRoleResponse } from './interface'

const useRole: ServiceHook<UseRoleResponse, UseRoleOptions> = (options) => {
  const [isLoading, setLoading] = useState(true)
  const [roleList, setRoleList] = useState<Role[]>([])
  const [defaultRole, setDefaultRole] = useState<Role | undefined>()

  const query = useQueryString()

  const roleKey = query.get('role_key') || undefined

  const roleRegistableQuery = useGetRoleRegistable({
    notifyOnNetworkStatusChange: true,
    variables: {
      roleKey,
    },
    context: {
      headers: {
        authorization: '',
        credentialKey: options?.credentialKey,
      },
    },
    onCompleted(resp) {
      const { roleList } = resp.getRoleRegisable.payload

      setRoleList(roleList)
      setDefaultRole(roleList[0])

      setLoading(false)
    },
  })

  const getRoleInfo = () => {
    let userFields: UserField[] = []
    let dataKeyList: string[] = []

    if (roleList.length === 1 || roleKey) {
      const currentRole = roleList[0]

      if (currentRole?.userField) {
        dataKeyList = currentRole.userField.filter((field) => !!field.dataKey).map((field) => field.dataKey as string)

        userFields = currentRole.userField
      }
    } else {
      const defaultRoleKey = options?.roleKey || 'GUEST'

      roleList.forEach((role) => {
        if (role.roleKey === defaultRoleKey) {
          dataKeyList = role.userField.filter((field) => !!field.dataKey).map((field) => field.dataKey as string)
          userFields = role.userField
        }
      })
    }

    return { userFields, dataKeyList }
  }

  return {
    ...getRoleInfo(),
    loading: isLoading || roleRegistableQuery.loading,
    roleList,
    defaultRole,
  }
}

export default useRole
