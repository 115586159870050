import { useQuery } from '@apollo/client'
import { message } from 'antd'

import { getEndpointByCredentialKeyType } from 'helpers/utils'

import QUERY_DOCUMENT from './getS3PutObjectSignedUrl'

import type {
  APIPayloadResponse,
  GraphQLServiceQueryHook,
  S3PutObjectSignedUrlInput,
} from 'graphQL/graphQL-service-hook'
import type { S3PutObjectAPIPayload } from './interface'

interface S3PutObjectSignedUrlData {
  getS3PutObjectSignedUrl: APIPayloadResponse<S3PutObjectAPIPayload[]>
}

interface S3PutObjectSignedUrlVars {
  userId?: string | null
  inputs?: S3PutObjectSignedUrlInput[]
}

const useGetS3PutObjectSignedUrl: GraphQLServiceQueryHook<S3PutObjectSignedUrlData, S3PutObjectSignedUrlVars> = (
  options
) => {
  return useQuery(QUERY_DOCUMENT, {
    context: {
      uri: getEndpointByCredentialKeyType(),
    },
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useGetS3PutObjectSignedUrl
