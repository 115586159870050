import React, { FC, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useQueryString from 'hooks/useQueryString'

import useGetAppByCredentialKey from 'graphQL/useGetAppByCredentialKey'

import FullScreenLoading from 'components/FullScreenLoading'

import { appLocalApp } from 'utils/localService'

import { authorizationKey } from 'config'

import { paths } from 'setup/PageRouter/PageRouter'

const withCredential =
  <T extends object>(WrappedComponent: React.ComponentType<T>): React.FC<T> =>
  (props) => {
    const { t } = useTranslation('global')

    const appFromLocalStorage = JSON.parse(appLocalApp.get() as string)

    const query = useQueryString()

    const lineLiffStateQuery = new URLSearchParams(query.get('liff.state')?.toString())

    const credential = lineLiffStateQuery.get(authorizationKey.credential) || query.get(authorizationKey.credential)
    const authType = lineLiffStateQuery.get(authorizationKey.authType) || query.get(authorizationKey.authType)

    const [isLoading, setLoading] = useState(appFromLocalStorage?.credential?.credentialKey !== credential)

    const shouldSkip = credential == null || !isLoading

    const isAccessToSignInFromExternalWebsite = Boolean(credential && authType)

    if (!isAccessToSignInFromExternalWebsite && window.location.pathname !== paths.signIn) {
      return <NavigateToSignInPage />
    }

    const appQuery = useGetAppByCredentialKey({
      skip: shouldSkip,
      variables: {
        credentialKey: String(credential),
      },
      context: {
        headers: {
          authorization: '',
        },
      },
      onCompleted(resp) {
        appLocalApp.set(JSON.stringify(resp.getAppByCredential.payload))

        setLoading(false)
      },
    })

    const app = appQuery.data?.getAppByCredential.payload

    if (appQuery.loading || isLoading) {
      return <FullScreenLoading>{t('loadingText')}</FullScreenLoading>
    }

    if (!!app && appQuery.loading) {
      return <NavigateToSignInPage />
    }

    return <WrappedComponent {...props} />
  }

export default withCredential

const NavigateToSignInPage: FC = () => {
  return <Navigate to={paths.signIn} />
}
