import React from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import FontawesomeIcon from 'components/FontAwesomeIcon'
import FullWidthSpace from 'components/FullWidthSpace'

interface EmailFormValues {
  email: string
}

interface EmailFormProps {
  loading?: boolean
  disabled?: boolean
  buttonName: string
  onFinish: (values: EmailFormValues) => void
}

const EmailForm: React.FC<EmailFormProps> = ({ loading, buttonName, disabled, onFinish }) => {
  const { t } = useTranslation('credentialApplicationSignIn')

  return (
    <Form onFinish={onFinish}>
      <FullWidthSpace size={16} direction="vertical">
        <Form.Item name="email" rules={[{ required: true, message: t('global:requiredText') }]}>
          <Input
            type="email"
            placeholder={t('global:inputPlaceholder', {
              placeholderName: t('label.email'),
            })}
            prefix={<FontawesomeIcon iconName="user" />}
          />
        </Form.Item>

        <Form.Item>
          <Button block loading={loading} disabled={disabled} type="primary" htmlType="submit">
            {t(`button.${buttonName}`)}
          </Button>
        </Form.Item>
      </FullWidthSpace>
    </Form>
  )
}

export default EmailForm
