import { ApolloClient, InMemoryCache } from '@apollo/client'
import { loader } from 'graphql.macro'

import { accessToken, clearStorage, locale, refreshToken } from 'services/localStorage'

import { getEndpointByCredentialKeyType } from 'helpers/utils'

import { getAppDataFromStorage } from 'utils/app'

import apolloClient from './apolloClient'

import type { AuthToken } from 'contexts/useAuthContext'
import type { RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables } from 'graphQL/generated/operations'
import { checkTokenExpired } from 'utils/token'

const MUTATION_DOCUMENT = loader('../../graphQL/documents/refreshAccessToken.graphql')

const client = new ApolloClient({
  uri: getEndpointByCredentialKeyType(),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

export const getToken = () =>
  new Promise<AuthToken | undefined>((resolve) => {
    const token = refreshToken.get()
    const accessTokenData = accessToken.get()

    const defaultToken: AuthToken = {
      accessToken: accessTokenData,
      refreshToken: token,
    }

    if (token == null) {
      resolve(undefined)
      return
    }

    if (!checkTokenExpired(accessTokenData)) {
      resolve(defaultToken)
      return
    }

    return client
      .mutate<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>({
        mutation: MUTATION_DOCUMENT,
        variables: {
          refreshToken: token,
        },
      })
      .then(({ data }) => {
        const newToken = data?.refreshAccessToken.payload

        if (newToken == null) {
          resolve(undefined)
          return
        }

        accessToken.set(newToken.accessToken)
        refreshToken.set(newToken.refreshToken)

        resolve(newToken)
      })
      .catch(() => {
        clearStorage()
        apolloClient.resetStore()

        window.location.reload()
      })
  })

export const getHeaders = (defaultHeaders: any, newAccessToken?: string) => {
  const credentialKey = getAppDataFromStorage()?.credential.credentialKey
  const localeCode = locale.get()

  const resultHeaders = {
    locale: localeCode === 'enUS' ? 'en' : 'th',
    authorization: newAccessToken != null ? `Bearer ${newAccessToken}` : undefined,
    ...defaultHeaders,
  }

  if (credentialKey == null) {
    return resultHeaders
  }

  return {
    credentialKey,
    ...resultHeaders,
  }
}
