import { gql } from '@apollo/client'

const GET_APP_THEME = gql`
  query getAppTheme($find: INPUT_FIND_DATA) {
    getAppTheme(find: $find) {
      message
      code
      payload {
        appKey
        name
        themeKey
        themeType
        status
        isDefault
        color
        image
        text
        custom
        credentialKey
      }
    }
  }
`

export default GET_APP_THEME
