import store from 'store'

import type { AppDataStorage } from 'utils/app'
import type { StorageName } from './types'

const storagePrefix = 'hooray-account'

export const storageVersion = '1.5'

export const localeCode = {
  enUS: 'enUS',
  thTH: 'thTH',
}

export const storageName: StorageName = {
  accessToken: `${storagePrefix}:accessToken`,
  refreshToken: `${storagePrefix}:refreshToken`,
  app: `${storagePrefix}:app`,
  locale: `${storagePrefix}:locale`,
  version: `${storagePrefix}:version`,
}

export const accessToken = {
  get: () => store.get(storageName.accessToken),
  set: (value: string) => store.set(storageName.accessToken, value),
}

export const refreshToken = {
  get: () => store.get(storageName.refreshToken),
  set: (value: string) => store.set(storageName.refreshToken, value),
}

export const app = {
  get: () => store.get(storageName.app),
  set: (value: AppDataStorage) => store.set(storageName.locale, value),
}

export const locale = {
  get: () => store.get(storageName.locale),
  set: (value: string) => store.set(storageName.locale, value),
}

export const version = {
  get: () => store.get(storageName.version),
  set: (value: string) => store.set(storageName.version, value),
}

export const clearStorage = () => {
  store.clearAll()

  locale.set(localeCode.thTH)
  version.set(storageVersion)
}

const setupStorage = () => {
  const currentVersion = version.get()

  if (currentVersion !== storageVersion) {
    clearStorage()
  }
}

export default setupStorage
