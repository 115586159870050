import React from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'
import enUS from 'antd/es/locale/en_US'
import thTH from 'antd/es/locale/th_TH'

import PageRouter from 'setup/PageRouter'
import apolloClient from 'setup/apolloClient'

import setupStorage, { localeCode } from 'services/localStorage'

import { AuthProvider } from 'contexts/useAuthContext'
import ThemeProvider from 'contexts/ThemeProvider'

import setupI18n from 'i18n'

import 'configuration'

import 'antd/dist/antd.min.css'
import 'assets/globalStyle.css'

setupStorage()
setupI18n()

const App: React.FC = () => {
  const { i18n } = useTranslation()
  const isThai = i18n.language === localeCode.thTH

  return (
    <ConfigProvider componentSize="large" locale={isThai ? thTH : enUS}>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <ThemeProvider>
            <PageRouter />
          </ThemeProvider>
        </AuthProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}

export default App
