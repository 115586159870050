import { useMutation } from '@apollo/client'
import { message } from 'antd'

import { isTokenExpiredError } from 'utils/apollo'
import { getEndpointByCredentialKeyType } from 'helpers/utils'

import MUTATION_DOCUMENT from './updateMyProfile'

import type { ProfileInput } from './interface'
import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { MyProfileAPIPayload } from 'graphQL/useGetMyProfile/interface'

interface UpdateMyProfileData {
  updateMyProfile: APIPayloadResponse<MyProfileAPIPayload>
}

interface UpdateMyProfileVars {
  input: ProfileInput
}

const useUpdateMyProfile: GraphQLServiceMutationHook<UpdateMyProfileData, UpdateMyProfileVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    context: {
      uri: getEndpointByCredentialKeyType(),
    },
    onError(error) {
      if (!isTokenExpiredError(error.graphQLErrors)) {
        message.error(error.message)
      }
    },
    ...options,
  })
}

export default useUpdateMyProfile
