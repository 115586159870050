import type { FC } from 'react'

import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import styled from 'styled-components'

interface LoadingIconProps {
  size?: number
}

const LoadingIcon: FC<LoadingIconProps> = ({ size, children }) => {
  const antdLoadingIcon = <LoadingOutlined style={{ fontSize: size || 48 }} spin />

  return <CustomSpinIcon indicator={antdLoadingIcon} tip={<TipLoadingText>{children}</TipLoadingText>} />
}

export default LoadingIcon

const CustomSpinIcon = styled(Spin)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 1rem;
  }
`

const TipLoadingText = styled.span`
  font-size: 18px;
`
