import { app } from 'services/localStorage'

import type { Application, Credential } from 'graphQL/graphQL-service-hook'

type S3UrlEndpointType = 'PRIVATE' | 'PUBLIC'

export interface AppDataStorage {
  app: Application
  credential: Credential
}

export const getAppDataFromStorage = (): AppDataStorage | undefined => app.get()

export const getS3UrlByType = (type: S3UrlEndpointType = 'PUBLIC'): string => {
  const app = getAppDataFromStorage()

  let s3Url = ''

  app?.app?.configurationList?.forEach((configuration) => {
    if (configuration.configKey === 'S3_PRIVATE_CONFIG' && type === 'PRIVATE') {
      s3Url = configuration.value.publicEndpoint
    } else if (configuration.configKey === 'S3_PUBLIC_CONFIG' && type === 'PUBLIC') {
      s3Url = configuration.value.publicEndpoint
    }
  })

  return s3Url
}
