import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  FileUpload: any
  JSON: any
  JSONObject: any
  Number: any
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum EnumAppStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EnumAuthType {
  AuthCode = 'AUTH_CODE',
  AuthToken = 'AUTH_TOKEN',
}

export enum EnumCategoryStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EnumConfigPrivacy {
  /** ไม่ระบุตัวตน */
  Anonymous = 'ANONYMOUS',
  /** ยืนยันตัวตน */
  AuthUser = 'AUTH_USER',
  /** ระบบ */
  System = 'SYSTEM',
}

export enum EnumCredentialType {
  ResourceAdmin = 'RESOURCE_ADMIN',
  ResourceOwner = 'RESOURCE_OWNER',
}

export enum EnumDataSecurity {
  None = 'NONE',
  Organization = 'ORGANIZATION',
  ParentChild = 'PARENT_CHILD',
  System = 'SYSTEM',
  User = 'USER',
}

export enum EnumGatewayApiType {
  Gql = 'GQL',
  Rest = 'REST',
}

export enum EnumGatewayMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Mutation = 'MUTATION',
  Post = 'POST',
  Put = 'PUT',
  Query = 'QUERY',
}

export enum EnumGatewayResType {
  Json = 'JSON',
  String = 'STRING',
}

export enum EnumGatewayStatus {
  Disable = 'DISABLE',
  Enabled = 'ENABLED',
}

export enum EnumGatewayUserRole {
  Aninymous = 'ANINYMOUS',
  ResourceAdmin = 'RESOURCE_ADMIN',
  ResourceOwner = 'RESOURCE_OWNER',
  SystemAdmin = 'SYSTEM_ADMIN',
}

export enum EnumGenerateKeyCapitalization {
  Lowercase = 'LOWERCASE',
  Uppercase = 'UPPERCASE',
}

export enum EnumInviteStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
}

export enum EnumIs {
  No = 'NO',
  Yes = 'YES',
}

export enum EnumLrleType {
  ResourceAdmin = 'RESOURCE_ADMIN',
  ResourceOwner = 'RESOURCE_OWNER',
  SystemAdmin = 'SYSTEM_ADMIN',
}

export enum EnumNotificationChannel {
  Email = 'EMAIL',
  MobileNotification = 'MOBILE_NOTIFICATION',
  Sms = 'SMS',
}

export enum EnumNotificationIsRequired {
  No = 'NO',
  Yes = 'YES',
}

export enum EnumNotificationMessageChannelCondition {
  AllUser = 'ALL_USER',
  CustomApplication = 'CUSTOM_APPLICATION',
  CustomEmail = 'CUSTOM_EMAIL',
  CustomPhoneNumber = 'CUSTOM_PHONE_NUMBER',
  UserRole = 'USER_ROLE',
  UserSelected = 'USER_SELECTED',
}

export enum EnumNotificationMessageChannelKey {
  Application = 'APPLICATION',
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum EnumNotificationMessageSendtype {
  System = 'SYSTEM',
  User = 'USER',
}

export enum EnumNotificationMessageStatus {
  Cancelled = 'CANCELLED',
  Prepare = 'PREPARE',
  Sent = 'SENT',
}

export enum EnumNotificationRead {
  No = 'NO',
  Yes = 'YES',
}

export enum EnumNotificationUserType {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  System = 'SYSTEM',
}

export enum EnumOrganizationApprovalStatus {
  Editable = 'EDITABLE',
  Locked = 'LOCKED',
}

export enum EnumOrganizationLabelStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EnumOrganizationStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  NeedMoreInformation = 'NEED_MORE_INFORMATION',
  Preparing = 'PREPARING',
  Rejected = 'REJECTED',
  Reviewing = 'REVIEWING',
  Suspended = 'SUSPENDED',
}

export enum EnumOrganizationTypeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EnumRoleAprovalAttributeStstus {
  Editable = 'EDITABLE',
  Locked = 'LOCKED',
}

export enum EnumRunningNumber {
  X = 'X',
  Xx = 'XX',
  Xxx = 'XXX',
  Xxxx = 'XXXX',
  Xxxxx = 'XXXXX',
}

export enum EnumRunningNumberDateformat {
  Yymm = 'YYMM',
  Yyyymm = 'YYYYMM',
  Yyyymmdd = 'YYYYMMDD',
  YyyymmdDhhmmss = 'YYYYMMDDhhmmss',
}

export enum EnumS3Acl {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum EnumServiceEndpoinType {
  Gql = 'GQL',
  Rest = 'REST',
}

export enum EnumSyncService {
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export enum EnumThemeDefaultStatus {
  No = 'NO',
  Yes = 'YES',
}

export enum EnumThemeReplaceableStatus {
  No = 'NO',
  Yes = 'YES',
}

export enum EnumThemeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EnumThemeType {
  Admin = 'ADMIN',
  Client = 'CLIENT',
}

export enum EnumUsability {
  Disable = 'DISABLE',
  Enabled = 'ENABLED',
}

export enum EnumUserRoleStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Declined = 'DECLINED',
  Preparing = 'PREPARING',
  Reviewing = 'REVIEWING',
}

export enum EnumVerifyStatus {
  NotVerify = 'NOT_VERIFY',
  Verified = 'VERIFIED',
}

export type File = {
  encoding: Scalars['String']
  filename: Scalars['String']
  mimetype: Scalars['String']
}

export type GetAppByEmail = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<GetAppByEmailCredentialPayload>>>
}

export type GetAppByEmailCredentialPayload = {
  app: Maybe<TypeAppSchema>
  credential: Maybe<GetAppByEmailCredentialSchema>
}

export type GetAppByEmailCredentialSchema = {
  credentialKey: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  status: Maybe<EnumAppStatus>
  type: Maybe<EnumCredentialType>
}

export type GetAppCredentialExpiration = {
  access: Maybe<Scalars['String']>
  refresh: Maybe<Scalars['String']>
}

export type GetAppCredentialHost = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<GetAppByEmailCredentialPayload>
}

export type GetAppCredentialList = {
  credentialKey: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  type: Maybe<Scalars['String']>
}

export type HoorayServerServiceInfo = {
  featureKeys: Maybe<Array<Maybe<HoorayServerServiceInfoFeature>>>
  graphqlEndpointAdmin: Maybe<Scalars['String']>
  graphqlEndpointClient: Maybe<Scalars['String']>
  graphqlEndpointSuperAdmin: Maybe<Scalars['String']>
  logo: Maybe<Scalars['String']>
  methodUpdate: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  permissions: Maybe<Array<Maybe<HoorayServerServiceInfoPermission>>>
  restEndpointAdmin: Maybe<Scalars['String']>
  restEndpointClient: Maybe<Scalars['String']>
  restEndpointSuperAdmin: Maybe<Scalars['String']>
  serviceKey: Maybe<Scalars['String']>
}

export type HoorayServerServiceInfoFeature = {
  description: Maybe<Scalars['String']>
  enable: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
}

export type HoorayServerServiceInfoPermission = {
  code: Maybe<Array<Maybe<Scalars['String']>>>
  defaultCode: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  permissionName: Maybe<Scalars['String']>
}

export type InputActive = {
  isActive: Scalars['Boolean']
}

export type InputAddEmail = {
  email?: InputMaybe<Scalars['String']>
}

export type InputAdmin = {
  attribute?: InputMaybe<Scalars['JSON']>
  email: Scalars['String']
}

export type InputAppForm = {
  appKey: Scalars['String']
  attribute?: InputMaybe<Scalars['JSON']>
  name: Scalars['String']
  status?: InputMaybe<EnumAppStatus>
}

export type InputAttribute = {
  _id?: InputMaybe<Scalars['ID']>
  key?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type InputCategory = {
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<EnumCategoryStatus>
}

export type InputConfigForm = {
  configKey?: InputMaybe<Scalars['String']>
  configName?: InputMaybe<Scalars['String']>
  isGlobal?: InputMaybe<Scalars['Boolean']>
  privacy?: InputMaybe<EnumConfigPrivacy>
  relatedConfig?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value?: InputMaybe<Scalars['JSON']>
}

export type InputCreateService = {
  endpointType: EnumServiceEndpoinType
  serviceKey: Scalars['String']
  systemEndpoint: Scalars['String']
}

export type InputCredentialForm = {
  credentialKey?: InputMaybe<Scalars['String']>
  expiration?: InputMaybe<InputExpiration>
  hostList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isAdminCredential?: InputMaybe<EnumIs>
  name?: InputMaybe<Scalars['String']>
  secretKey?: InputMaybe<Scalars['String']>
  status?: InputMaybe<EnumAppStatus>
  type?: InputMaybe<EnumCredentialType>
}

export type InputCredentialFormUpdate = {
  expiration?: InputMaybe<InputExpiration>
  hostList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<EnumAppStatus>
  type?: InputMaybe<EnumCredentialType>
}

export type InputDataSecurityForm = {
  assign: EnumDataSecurity
  assignToParent: EnumIs
  create: EnumDataSecurity
  delete: EnumDataSecurity
  name: Scalars['String']
  read: EnumDataSecurity
  securityKey: Scalars['String']
  write: EnumDataSecurity
}

export type InputEmailRegisterInput = {
  attribute?: InputMaybe<Scalars['JSON']>
  confirmPassword: Scalars['String']
  email: Scalars['String']
  inviteCode?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  roleKey?: InputMaybe<Scalars['String']>
}

export type InputExpiration = {
  access?: InputMaybe<Scalars['String']>
  refresh?: InputMaybe<Scalars['String']>
}

export type InputFeatureForm = {
  config?: InputMaybe<Scalars['JSON']>
  featureKey: Scalars['String']
}

export type InputFilter = {
  key?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type InputFilterDate = {
  endDate?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
}

export type InputFindData = {
  filter?: InputMaybe<Scalars['JSON']>
  findManyById?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  findOneById?: InputMaybe<Scalars['ID']>
  pagination?: InputMaybe<InputPagination>
  query?: InputMaybe<Scalars['JSON']>
  search?: InputMaybe<Scalars['JSON']>
  sort?: InputMaybe<Scalars['JSON']>
}

export type InputGateway = {
  apiType?: InputMaybe<EnumGatewayApiType>
  destination?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  endpont?: InputMaybe<Scalars['String']>
  header?: InputMaybe<Scalars['JSON']>
  key?: InputMaybe<Scalars['String']>
  method?: InputMaybe<EnumGatewayMethod>
  name?: InputMaybe<Scalars['String']>
  queryString?: InputMaybe<Scalars['JSON']>
  resType?: InputMaybe<EnumGatewayResType>
  schema?: InputMaybe<Scalars['JSON']>
  source?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<EnumGatewayStatus>
  userRole?: InputMaybe<EnumGatewayUserRole>
  variable?: InputMaybe<Scalars['JSON']>
}

export type InputGenerateRunningNumber = {
  runningKey?: InputMaybe<Scalars['String']>
}

export type InputInviteEmailList = {
  email?: InputMaybe<Scalars['String']>
  roleKey?: InputMaybe<Scalars['String']>
}

export type InputLocaleTextForm = {
  attributes?: InputMaybe<Scalars['JSON']>
  dataKey: Scalars['String']
  locale: Scalars['String']
  orgKey?: InputMaybe<Scalars['String']>
  text: Scalars['String']
}

export type InputLoginAuthCode = {
  email: Scalars['String']
  password: Scalars['String']
  redirectUrl: Scalars['String']
}

export type InputMasterData = {
  attributes?: InputMaybe<Scalars['JSON']>
  dataKey: Scalars['String']
  locale: Scalars['String']
  parent?: InputMaybe<Scalars['String']>
  text: Scalars['String']
}

export type InputNotification = {
  attribute?: InputMaybe<Scalars['JSON']>
  content?: InputMaybe<Scalars['String']>
  icon?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<EnumNotificationUserType>
}

export type InputNotificationDataKey = {
  dataKey?: InputMaybe<Scalars['String']>
  defaultValue?: InputMaybe<Scalars['String']>
  isRequired?: InputMaybe<EnumNotificationIsRequired>
}

export type InputNotificationMessage = {
  content?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type InputNotificationMessageChannel = {
  condition: EnumNotificationMessageChannelCondition
  key: EnumNotificationMessageChannelKey
}

export type InputNumberAttribute = {
  _id?: InputMaybe<Scalars['ID']>
  key?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['Number']>
}

export type InputOrganizationApprovalForm = {
  approvalAttribute?: InputMaybe<Scalars['JSON']>
  orgKey: Scalars['String']
}

export type InputOrganizationForm = {
  address?: InputMaybe<Scalars['JSON']>
  attribute?: InputMaybe<Scalars['JSON']>
  categoryList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  contactEmailList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contactName?: InputMaybe<Scalars['String']>
  location?: InputMaybe<InputOrganizationLocation>
  name?: InputMaybe<Scalars['String']>
  orgKey?: InputMaybe<Scalars['String']>
  organizationLabelList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  organizationType?: InputMaybe<Scalars['ID']>
  parentOrgKey?: InputMaybe<Scalars['String']>
}

export type InputOrganizationLocalList = {
  dataKey?: InputMaybe<Scalars['String']>
  masterKey?: InputMaybe<Scalars['String']>
}

export type InputOrganizationLocation = {
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Number']>>>
}

export type InputOrganizationMasterDetailList = {
  dataKey?: InputMaybe<Scalars['String']>
  masterKey?: InputMaybe<Scalars['String']>
}

export type InputOrganizationTagList = {
  masterKey?: InputMaybe<Scalars['String']>
  tagKey?: InputMaybe<Scalars['String']>
}

export type InputOrganizationUpdate = {
  address?: InputMaybe<Scalars['JSON']>
  attribute?: InputMaybe<Scalars['JSON']>
  categoryList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  contactEmailList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contactName?: InputMaybe<Scalars['String']>
  location?: InputMaybe<InputOrganizationLocation>
  name?: InputMaybe<Scalars['String']>
  orgKey?: InputMaybe<Scalars['String']>
  organizationLabelList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  organizationType?: InputMaybe<Scalars['ID']>
  parentOrgKey?: InputMaybe<Scalars['String']>
}

export type InputOrganiztionLabel = {
  orgLabelDescription?: InputMaybe<Scalars['String']>
  orgLabelIcon?: InputMaybe<Scalars['String']>
  orgLabelName?: InputMaybe<Scalars['String']>
  orgLabelStatus?: InputMaybe<EnumOrganizationLabelStatus>
  orgLabelTitle?: InputMaybe<Scalars['String']>
}

export type InputOrganiztionType = {
  needApprove?: InputMaybe<EnumIs>
  orgFieldList?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  orgTypeKey?: InputMaybe<Scalars['String']>
  orgTypeName?: InputMaybe<Scalars['String']>
  orgTypeStatus?: InputMaybe<EnumOrganizationTypeStatus>
}

export type InputOrgAdminRegisterInput = {
  attribute?: InputMaybe<Scalars['JSON']>
  confirmPassword: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  verifyToken?: InputMaybe<Scalars['String']>
}

export type InputOrgApprovalAttributeStatus = {
  note?: InputMaybe<Scalars['String']>
  orgKey: Scalars['String']
  status: EnumOrganizationApprovalStatus
}

export type InputOrgStatus = {
  note?: InputMaybe<Scalars['String']>
  orgKey: Scalars['String']
  status: EnumOrganizationStatus
}

export type InputOAuthForm = {
  loginStatus?: InputMaybe<EnumUsability>
  reauthentication?: InputMaybe<EnumUsability>
  redirectUrlList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type InputPagination = {
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
}

export type InputPermissionForm = {
  code?: InputMaybe<Array<Scalars['String']>>
  permissionKey: Scalars['String']
  scopeList?: InputMaybe<Array<Scalars['String']>>
}

export type InputPermissionRole = {
  code: Scalars['String']
  permissionName: Scalars['String']
  scpes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type InputRequestOtp = {
  countryCode: Scalars['String']
  phoneNumber: Scalars['Number']
}

export type InputRoleApprovalAttributeStatus = {
  note?: InputMaybe<Scalars['String']>
  roleKey: Scalars['String']
  status?: InputMaybe<EnumRoleAprovalAttributeStstus>
  userId: Scalars['String']
}

export type InputRoleFrom = {
  attribute?: InputMaybe<Scalars['JSON']>
  isDefault?: InputMaybe<EnumIs>
  isInvite?: InputMaybe<EnumIs>
  isReplaceable?: InputMaybe<EnumIs>
  name?: InputMaybe<Scalars['String']>
  needApproval?: InputMaybe<EnumIs>
  regisable?: InputMaybe<EnumIs>
  roleKey?: InputMaybe<Scalars['String']>
  roleType?: InputMaybe<EnumLrleType>
  status?: InputMaybe<EnumAppStatus>
  userField?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
}

export type InputRunningNumber = {
  attribute?: InputMaybe<Scalars['JSON']>
  formatDate?: InputMaybe<EnumRunningNumberDateformat>
  lastText?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  numberRunning?: InputMaybe<EnumRunningNumber>
  runningKey?: InputMaybe<Scalars['String']>
  text?: InputMaybe<Scalars['String']>
}

export type InputS3PutObjectSignedUrl = {
  acl?: InputMaybe<EnumS3Acl>
  contentType?: InputMaybe<Scalars['String']>
  objectName?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
}

export type InputSchemaCustomMenu = {
  actionColor?: InputMaybe<Scalars['String']>
  icon?: InputMaybe<Scalars['String']>
  menuKey?: InputMaybe<Scalars['String']>
  permissionList?: InputMaybe<Array<InputMaybe<InputSchemaCustomMenuPermissionList>>>
  status?: InputMaybe<EnumUsability>
  target?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['JSON']>
  url?: InputMaybe<Scalars['String']>
}

export type InputSchemaCustomMenuPermissionList = {
  code?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  permissionKey?: InputMaybe<Scalars['String']>
}

export type InputScmemaNotificationTemplate = {
  channel?: InputMaybe<EnumNotificationChannel>
  dataKeyList?: InputMaybe<Array<InputMaybe<InputNotificationDataKey>>>
  locale?: InputMaybe<Scalars['String']>
  templateKey: Scalars['String']
  templateValue: Scalars['String']
  title?: InputMaybe<Scalars['String']>
}

export type InputSearch = {
  condition?: InputMaybe<Scalars['String']>
  searchAttribute?: InputMaybe<Array<InputMaybe<InputSearchAttribute>>>
}

export type InputSearchAttribute = {
  key?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type InputSenderList = {
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type InputSendMailForm = {
  email?: InputMaybe<Array<Scalars['String']>>
  name?: InputMaybe<Scalars['String']>
  subject?: InputMaybe<Scalars['String']>
  text: Scalars['String']
}

export type InputSmtpForm = {
  host?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  port?: InputMaybe<Scalars['Number']>
  securedConnection?: InputMaybe<Scalars['Boolean']>
  senderEmail?: InputMaybe<Scalars['String']>
  senderName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type InputThemeForm = {
  color?: InputMaybe<Scalars['JSON']>
  credentialKey?: InputMaybe<Scalars['String']>
  custom?: InputMaybe<Scalars['JSON']>
  image?: InputMaybe<Scalars['JSON']>
  isDefault?: InputMaybe<EnumThemeDefaultStatus>
  isReplaceable?: InputMaybe<EnumThemeReplaceableStatus>
  name: Scalars['String']
  status: EnumThemeStatus
  text?: InputMaybe<Scalars['JSON']>
  themeKey: Scalars['String']
  themeType?: InputMaybe<EnumThemeType>
}

export type InputThemeFormColor = {
  navAuthBg?: InputMaybe<Scalars['String']>
  navAuthText?: InputMaybe<Scalars['String']>
  navBg?: InputMaybe<Scalars['String']>
  navText?: InputMaybe<Scalars['String']>
  primary?: InputMaybe<Scalars['String']>
  secondary?: InputMaybe<Scalars['String']>
  titleText?: InputMaybe<Scalars['String']>
}

export type InputThemeFormImage = {
  favIcon?: InputMaybe<Scalars['String']>
  navAuthCollapsedLogo?: InputMaybe<Scalars['String']>
  navAuthLogo?: InputMaybe<Scalars['String']>
  navLogo?: InputMaybe<Scalars['String']>
  webLogo?: InputMaybe<Scalars['String']>
}

export type InputThemeFormText = {
  description?: InputMaybe<Scalars['String']>
  navTitle?: InputMaybe<Scalars['String']>
  webTitle?: InputMaybe<Scalars['String']>
}

export type InputUpdateApp = {
  attribute?: InputMaybe<Scalars['JSON']>
  name: Scalars['String']
  status?: InputMaybe<EnumAppStatus>
}

export type InputUpdateProfile = {
  attribute?: InputMaybe<Scalars['JSON']>
  roleList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  setting?: InputMaybe<Scalars['JSON']>
}

export type InputUsernameRegister = {
  attribute?: InputMaybe<Scalars['JSON']>
  confirmPassword: Scalars['String']
  password: Scalars['String']
  roleKey?: InputMaybe<Scalars['String']>
  username: Scalars['String']
}

export type InputUserRoleStatus = {
  note?: InputMaybe<Scalars['String']>
  roleKey: Scalars['String']
  status?: InputMaybe<EnumUserRoleStatus>
  userId?: InputMaybe<Scalars['ID']>
}

export type InputVerifyEmail = {
  email: Scalars['String']
  verifyToken: Scalars['String']
}

export type InputVerifyPasswordEmail = {
  confirmPassword: Scalars['String']
  email: Scalars['String']
  newPassword: Scalars['String']
  verifyToken: Scalars['String']
}

export type InputVerifyPasswordOtp = {
  confirmPassword: Scalars['String']
  countryCode: Scalars['String']
  newPassword: Scalars['String']
  otp: Scalars['Number']
  phoneNumber: Scalars['Number']
  ref: Scalars['String']
}

export type InputVerifyPasswordSystemAdminEmail = {
  attribute?: InputMaybe<Scalars['JSON']>
  confirmPassword: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  verifyToken: Scalars['String']
}

export type Mutation = {
  addEmailGlobal: Maybe<TypeOk>
  addGateway: Maybe<TypeGatewayList>
  addOrgAdmin: Maybe<TypeProfile>
  addServiceToApp: Maybe<TypeAppService>
  cancelNotificationMessage: Maybe<TypeNotificationMessage>
  changeOrgApprovalAttributeStatus: Maybe<TypeOrganizationApprovalResponse>
  changeOrgStatus: Maybe<TypeOrganization>
  changePassword: Maybe<TypeProfile>
  changeResourceAdminRoleApprovalAttributeStatus: Maybe<TypeRoleAprovalAttribute>
  changeResourceAdminRoleStatus: Maybe<TypeRoleAprovalAttribute>
  changeResourceOwnerRoleApprovalAttributeStatus: Maybe<TypeRoleAprovalAttribute>
  changeResourceOwnerRoleStatus: Maybe<TypeRoleAprovalAttribute>
  checkVerifyEmailOrg: Maybe<TypeCheckVerifyEmail>
  confirmOrgAdmin: Maybe<TypeProfile>
  confirmPhoneNumberUser: Maybe<TypeJson>
  createCategory: Maybe<TypeCategory>
  createCredential: Maybe<TypeCredential>
  createCustomMenu: Maybe<TypeCustomMenu>
  createLocaleText: Maybe<TypeLocaleText>
  createLocaleTextList: Maybe<TypeLocaleTextList>
  createNotification: Maybe<TypeNotificationList>
  createNotificationMessage: Maybe<TypeNotificationMessageCountPayload>
  createNotificationMessageForService: Maybe<TypeNotificationMessageCountPayload>
  createNotificationTemplate: Maybe<TypeNotificationTemplate>
  createOrganization: Maybe<TypeOrganization>
  createOrganizationLabel: Maybe<TypeOrganizationLabel>
  createOrganizationType: Maybe<TypeOrganizationType>
  createRole: Maybe<TypeAppRole>
  createRunningNumber: Maybe<TypeRunningNumber>
  deleteCategory: Maybe<TypeCategory>
  deleteCredential: Maybe<TypeCredential>
  deleteCustomMenu: Maybe<TypeCustomMenuList>
  deleteDataSecurity: Maybe<TypeRoleDataSecurityList>
  deleteEmailContact: Maybe<TypeOrganization>
  deleteGateway: Maybe<TypeGatewayList>
  deleteInvite: Maybe<TypeInvite>
  deleteLocaleText: Maybe<TypeLocaleText>
  deleteMasterData: Maybe<TypeMasterData>
  deleteMyUser: Maybe<TypeProfile>
  deleteNotificationTemplate: Maybe<TypeNotificationTemplate>
  deleteOrgAdmin: Maybe<TypeProfile>
  deleteOrganization: Maybe<TypeOrganization>
  deleteOrganizationLabel: Maybe<TypeOrganizationLabel>
  deleteOrganizationType: Maybe<TypeOrganizationType>
  deletePermission: Maybe<TypeRolePermissionList>
  deleteResourceAdmin: Maybe<TypeProfile>
  deleteResourceAdminPermission: Maybe<TypeRolePermissionList>
  deleteResourceOwnerPermission: Maybe<TypeRolePermissionList>
  deleteRole: Maybe<TypeAppRole>
  deleteRunningNumber: Maybe<TypeRunningNumber>
  deleteTheme: Maybe<TypeThemeResponse>
  deleteUserOrganization: Maybe<TypeInvite>
  deleteUserOrganizationByUserId: Maybe<TypeInvite>
  deleteVersionControl: Maybe<TypeVersionControl>
  disableServiceFeature: Maybe<TypeFeatureList>
  enableServiceFeature: Maybe<TypeFeatureList>
  generateAuthCode: Maybe<TypeLogin>
  generateRunningNumber: Maybe<TypeGenerateRunningNumber>
  getOrgAccessToken: Maybe<TypeOrgAcceesToken>
  getS3GetObjectSignedUrl: Maybe<TypeS3SignedUrl>
  getS3PutObjectSignedUrl: Maybe<TypeS3SignedUrl>
  getTokenAuthCode: Maybe<TypeLogin>
  inviteOrganizationCode: Maybe<TypeOrganization>
  inviteUserOrganization: Maybe<TypeInvite>
  logOutUser: Maybe<TypeLogout>
  loginApple: Maybe<TypeLogin>
  loginEmail: Maybe<TypeLogin>
  loginFacebook: Maybe<TypeLogin>
  loginGoogle: Maybe<TypeLogin>
  loginLine: Maybe<TypeLogin>
  loginPhoneNumber: Maybe<TypeLogin>
  loginUsername: Maybe<TypeLogin>
  loginWithAuthCode: Maybe<TypeAuthCode>
  readAllNotification: Maybe<TypeNotificationList>
  readNotification: Maybe<TypeNotificationList>
  refreshAccessToken: Maybe<TypeRefreshAccessToken>
  refreshOrgAccessToken: Maybe<TypeOrgAcceesToken>
  registerEmail: Maybe<TypeEmailRegister>
  registerOrgAdmin: Maybe<TypeEmailRegister>
  registerUsername: Maybe<TypeUsernameRegister>
  requestOtp: Maybe<TypeJson>
  resendRegisterEmail: Maybe<TypeEmailRegister>
  resetPasswordEmail: Maybe<TypeResetPasswordEmail>
  resetPasswordOtp: Maybe<TypeResetPasswordEmail>
  s3UploadFile: Maybe<TypeS3SignedUrl>
  sendContactMail: Maybe<TypeOk>
  sendToVerifyEmailOrg: Maybe<TypeOk>
  singleUpload: File
  syncOrganization: Maybe<TypeSyncOrganization>
  updateAppAttribute: Maybe<TypeApp>
  updateCategory: Maybe<TypeCategory>
  updateConfig: Maybe<TypeConfig>
  updateCredential: Maybe<TypeCredential>
  updateCredentialKey: Maybe<TypeCredential>
  updateCustomMenu: Maybe<TypeCustomMenu>
  updateDataSecurity: Maybe<TypeRoleDataSecurityList>
  updateGateway: Maybe<TypeGatewayList>
  updateLocaleText: Maybe<TypeLocaleText>
  updateMyProfile: Maybe<TypeProfile>
  updateMyRoleApprovalAttribute: Maybe<TypeRoleAprovalAttribute>
  updateNotificationTemplate: Maybe<TypeNotificationTemplate>
  updateOAuth: Maybe<TypeOAuth>
  updateOrgAdminRole: Maybe<TypeProfile>
  updateOrgApprovalAttribute: Maybe<TypeOrganizationApprovalResponse>
  updateOrgKey: Maybe<TypeOrganization>
  updateOrgStatus: Maybe<TypeOrganization>
  updateOrganization: Maybe<TypeOrganization>
  updateOrganizationLabel: Maybe<TypeOrganizationLabel>
  updateOrganizationType: Maybe<TypeOrganizationType>
  updatePermission: Maybe<TypeRolePermissionList>
  updateResourceAdmin: Maybe<TypeProfile>
  updateResourceAdminPermission: Maybe<TypeRolePermissionList>
  updateResourceOwnerPermission: Maybe<TypeRolePermissionList>
  updateResourceOwnerProfile: Maybe<TypeProfile>
  updateRole: Maybe<TypeAppRole>
  updateSecretKey: Maybe<TypeCredential>
  updateTheme: Maybe<TypeThemeResponse>
  updateVersionControl: Maybe<TypeVersionControl>
  verifyEmail: Maybe<TypeEmailRegister>
  verifyEmailGlobal: Maybe<TypeOk>
  verifyEmailOrganization: Maybe<TypeOrganization>
  verifyInviteOrganization: Maybe<TypeInvite>
  verifyOtp: Maybe<TypeJson>
  verifyPasswordEmail: Maybe<TypeResetPasswordEmail>
  verifyPhoneNumberUser: Maybe<TypeJson>
}

export type MutationAddEmailGlobalArgs = {
  input?: InputMaybe<InputAddEmail>
}

export type MutationAddGatewayArgs = {
  input: InputGateway
}

export type MutationAddOrgAdminArgs = {
  email: Scalars['String']
  orgKey: Scalars['String']
  roleList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MutationAddServiceToAppArgs = {
  serviceKey: Scalars['String']
}

export type MutationCancelNotificationMessageArgs = {
  notificationMessageIds: Array<Scalars['ID']>
}

export type MutationChangeOrgApprovalAttributeStatusArgs = {
  input?: InputMaybe<InputOrgApprovalAttributeStatus>
}

export type MutationChangeOrgStatusArgs = {
  input: InputOrgStatus
}

export type MutationChangePasswordArgs = {
  confirmPassword: Scalars['String']
  newPassword: Scalars['String']
  password: Scalars['String']
}

export type MutationChangeResourceAdminRoleApprovalAttributeStatusArgs = {
  input?: InputMaybe<InputRoleApprovalAttributeStatus>
}

export type MutationChangeResourceAdminRoleStatusArgs = {
  input?: InputMaybe<InputUserRoleStatus>
}

export type MutationChangeResourceOwnerRoleApprovalAttributeStatusArgs = {
  input?: InputMaybe<InputRoleApprovalAttributeStatus>
}

export type MutationChangeResourceOwnerRoleStatusArgs = {
  input?: InputMaybe<InputUserRoleStatus>
}

export type MutationCheckVerifyEmailOrgArgs = {
  email: Scalars['String']
}

export type MutationConfirmOrgAdminArgs = {
  email: Scalars['String']
  verifyToken?: InputMaybe<Scalars['String']>
}

export type MutationConfirmPhoneNumberUserArgs = {
  otpCode: Scalars['String']
  phoneNumber: Scalars['String']
}

export type MutationCreateCategoryArgs = {
  input?: InputMaybe<InputCategory>
}

export type MutationCreateCredentialArgs = {
  credentialInput?: InputMaybe<InputCredentialForm>
}

export type MutationCreateCustomMenuArgs = {
  input?: InputMaybe<InputSchemaCustomMenu>
}

export type MutationCreateLocaleTextArgs = {
  input?: InputMaybe<InputLocaleTextForm>
}

export type MutationCreateLocaleTextListArgs = {
  inputList: Array<InputLocaleTextForm>
}

export type MutationCreateNotificationArgs = {
  from: Scalars['String']
  input: InputNotification
  to: Array<Scalars['String']>
}

export type MutationCreateNotificationMessageArgs = {
  channel?: InputMaybe<InputNotificationMessageChannel>
  input?: InputMaybe<InputNotificationMessage>
  sendAt: Scalars['Date']
  serviceKey: Scalars['String']
  to: Array<Scalars['String']>
}

export type MutationCreateNotificationMessageForServiceArgs = {
  appKey: Scalars['String']
  channel?: InputMaybe<InputNotificationMessageChannel>
  input?: InputMaybe<InputNotificationMessage>
  sendAt: Scalars['Date']
  serviceKey: Scalars['String']
  to: Array<Scalars['String']>
}

export type MutationCreateNotificationTemplateArgs = {
  input?: InputMaybe<InputScmemaNotificationTemplate>
}

export type MutationCreateOrganizationArgs = {
  input?: InputMaybe<InputOrganizationForm>
}

export type MutationCreateOrganizationLabelArgs = {
  input?: InputMaybe<InputOrganiztionLabel>
}

export type MutationCreateOrganizationTypeArgs = {
  input?: InputMaybe<InputOrganiztionType>
}

export type MutationCreateRoleArgs = {
  roleInput?: InputMaybe<InputRoleFrom>
}

export type MutationCreateRunningNumberArgs = {
  input?: InputMaybe<InputRunningNumber>
}

export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['String']
}

export type MutationDeleteCredentialArgs = {
  credentialKey: Scalars['String']
}

export type MutationDeleteCustomMenuArgs = {
  customMenuIdList: Array<InputMaybe<Scalars['ID']>>
}

export type MutationDeleteDataSecurityArgs = {
  roleKey: Scalars['String']
  securityKeyList?: InputMaybe<Array<Scalars['String']>>
}

export type MutationDeleteEmailContactArgs = {
  email: Scalars['String']
  orgKey: Scalars['String']
}

export type MutationDeleteGatewayArgs = {
  gatewayId: Scalars['ID']
}

export type MutationDeleteInviteArgs = {
  inviteId: Scalars['ID']
  orgKey: Scalars['String']
}

export type MutationDeleteLocaleTextArgs = {
  dataKey: Scalars['String']
  orgKey?: InputMaybe<Scalars['String']>
}

export type MutationDeleteMasterDataArgs = {
  changeTo?: InputMaybe<Scalars['String']>
  dataKey: Scalars['String']
}

export type MutationDeleteNotificationTemplateArgs = {
  templateKey: Scalars['String']
}

export type MutationDeleteOrgAdminArgs = {
  orgKey: Scalars['String']
  userId: Scalars['ID']
}

export type MutationDeleteOrganizationArgs = {
  orgKey: Scalars['String']
}

export type MutationDeleteOrganizationLabelArgs = {
  orgLabelId: Scalars['ID']
}

export type MutationDeleteOrganizationTypeArgs = {
  orgTypeId: Scalars['ID']
}

export type MutationDeletePermissionArgs = {
  permissionKeyList: Array<Scalars['String']>
  roleKey: Scalars['String']
}

export type MutationDeleteResourceAdminArgs = {
  userId: Scalars['ID']
}

export type MutationDeleteResourceAdminPermissionArgs = {
  appKey: Scalars['String']
  permissionKeyList?: InputMaybe<Array<Scalars['String']>>
  roleKey: Scalars['String']
}

export type MutationDeleteResourceOwnerPermissionArgs = {
  appKey: Scalars['String']
  permissionKeyList?: InputMaybe<Array<Scalars['String']>>
  roleKey: Scalars['String']
}

export type MutationDeleteRoleArgs = {
  roleKeyToDelete: Scalars['String']
  roleKeyToMove: Scalars['String']
}

export type MutationDeleteRunningNumberArgs = {
  runningId?: InputMaybe<Array<Scalars['String']>>
}

export type MutationDeleteThemeArgs = {
  themeKey: Scalars['String']
}

export type MutationDeleteUserOrganizationArgs = {
  inviteId: Scalars['ID']
  orgKey: Scalars['String']
}

export type MutationDeleteUserOrganizationByUserIdArgs = {
  orgKey: Scalars['String']
  userId: Scalars['ID']
}

export type MutationDeleteVersionControlArgs = {
  versionKey?: InputMaybe<Scalars['String']>
}

export type MutationDisableServiceFeatureArgs = {
  featureKeyList?: InputMaybe<Array<Scalars['String']>>
}

export type MutationEnableServiceFeatureArgs = {
  featureKeyList?: InputMaybe<Array<Scalars['String']>>
}

export type MutationGenerateRunningNumberArgs = {
  input?: InputMaybe<InputGenerateRunningNumber>
}

export type MutationGetOrgAccessTokenArgs = {
  accessToken: Scalars['String']
  orgKey: Scalars['String']
}

export type MutationGetS3GetObjectSignedUrlArgs = {
  fileKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MutationGetS3PutObjectSignedUrlArgs = {
  inputs?: InputMaybe<Array<InputMaybe<InputS3PutObjectSignedUrl>>>
  userId?: InputMaybe<Scalars['ID']>
}

export type MutationGetTokenAuthCodeArgs = {
  code: Scalars['String']
}

export type MutationInviteOrganizationCodeArgs = {
  inviteCode: Scalars['String']
}

export type MutationInviteUserOrganizationArgs = {
  emailList?: InputMaybe<Array<InputMaybe<InputInviteEmailList>>>
  orgKey: Scalars['String']
}

export type MutationLogOutUserArgs = {
  userId: Scalars['String']
}

export type MutationLoginAppleArgs = {
  appleToken: Scalars['String']
  userId: Scalars['String']
}

export type MutationLoginEmailArgs = {
  authType: EnumAuthType
  email: Scalars['String']
  password: Scalars['String']
  redirectUrl?: InputMaybe<Scalars['String']>
}

export type MutationLoginFacebookArgs = {
  authType: EnumAuthType
  facebookToken: Scalars['String']
  redirectUrl?: InputMaybe<Scalars['String']>
}

export type MutationLoginGoogleArgs = {
  authType: EnumAuthType
  googleToken: Scalars['String']
  redirectUrl?: InputMaybe<Scalars['String']>
}

export type MutationLoginLineArgs = {
  authType: EnumAuthType
  lineToken: Scalars['String']
  redirectUrl?: InputMaybe<Scalars['String']>
}

export type MutationLoginPhoneNumberArgs = {
  phoneNumber: Scalars['String']
  redirectUrl?: InputMaybe<Scalars['String']>
}

export type MutationLoginUsernameArgs = {
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationLoginWithAuthCodeArgs = {
  input: InputLoginAuthCode
}

export type MutationReadNotificationArgs = {
  notificationIdList: Array<Scalars['String']>
}

export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']
}

export type MutationRefreshOrgAccessTokenArgs = {
  refreshOrgToken: Scalars['String']
}

export type MutationRegisterEmailArgs = {
  input?: InputMaybe<InputEmailRegisterInput>
}

export type MutationRegisterOrgAdminArgs = {
  input?: InputMaybe<InputOrgAdminRegisterInput>
}

export type MutationRegisterUsernameArgs = {
  input?: InputMaybe<InputUsernameRegister>
}

export type MutationRequestOtpArgs = {
  phoneNumber: Scalars['String']
}

export type MutationResendRegisterEmailArgs = {
  email: Scalars['String']
}

export type MutationResetPasswordEmailArgs = {
  email: Scalars['String']
}

export type MutationResetPasswordOtpArgs = {
  input?: InputMaybe<InputRequestOtp>
}

export type MutationS3UploadFileArgs = {
  file?: InputMaybe<Scalars['FileUpload']>
  userId?: InputMaybe<Scalars['ID']>
}

export type MutationSendContactMailArgs = {
  input?: InputMaybe<InputSendMailForm>
}

export type MutationSendToVerifyEmailOrgArgs = {
  email: Scalars['String']
}

export type MutationSingleUploadArgs = {
  file: Scalars['FileUpload']
}

export type MutationSyncOrganizationArgs = {
  orgKeyList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MutationUpdateAppAttributeArgs = {
  attributeInput: Scalars['JSON']
}

export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['String']
  input?: InputMaybe<InputCategory>
}

export type MutationUpdateConfigArgs = {
  input?: InputMaybe<InputConfigForm>
}

export type MutationUpdateCredentialArgs = {
  credentialInput?: InputMaybe<InputCredentialFormUpdate>
  credentialKey: Scalars['String']
}

export type MutationUpdateCredentialKeyArgs = {
  credentialKey: Scalars['String']
  newCredentialKey: Scalars['String']
}

export type MutationUpdateCustomMenuArgs = {
  customMenuId: Scalars['ID']
  input?: InputMaybe<InputSchemaCustomMenu>
}

export type MutationUpdateDataSecurityArgs = {
  roleKey: Scalars['String']
  securityList?: InputMaybe<Array<InputMaybe<InputDataSecurityForm>>>
}

export type MutationUpdateGatewayArgs = {
  gatewayId: Scalars['ID']
  input: InputGateway
}

export type MutationUpdateLocaleTextArgs = {
  dataInput?: InputMaybe<InputLocaleTextForm>
  dataKey: Scalars['String']
  orgKey?: InputMaybe<Scalars['String']>
}

export type MutationUpdateMyProfileArgs = {
  input?: InputMaybe<InputUpdateProfile>
}

export type MutationUpdateMyRoleApprovalAttributeArgs = {
  roleKey: Scalars['String']
  secureAttibute?: InputMaybe<Scalars['JSON']>
}

export type MutationUpdateNotificationTemplateArgs = {
  templateInput?: InputMaybe<InputScmemaNotificationTemplate>
  templateKey: Scalars['String']
}

export type MutationUpdateOAuthArgs = {
  input?: InputMaybe<InputOAuthForm>
}

export type MutationUpdateOrgAdminRoleArgs = {
  orgKey: Scalars['String']
  roleList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  userId: Scalars['ID']
}

export type MutationUpdateOrgApprovalAttributeArgs = {
  input?: InputMaybe<InputOrganizationApprovalForm>
}

export type MutationUpdateOrgKeyArgs = {
  newOrgKey: Scalars['String']
  orgKey: Scalars['String']
}

export type MutationUpdateOrgStatusArgs = {
  note?: InputMaybe<Scalars['String']>
  orgKey: Scalars['String']
  status?: InputMaybe<EnumOrganizationStatus>
}

export type MutationUpdateOrganizationArgs = {
  input?: InputMaybe<InputOrganizationUpdate>
  orgKey: Scalars['String']
  parentOrgKey?: InputMaybe<Scalars['String']>
}

export type MutationUpdateOrganizationLabelArgs = {
  input?: InputMaybe<InputOrganiztionLabel>
  orgLabelId: Scalars['ID']
}

export type MutationUpdateOrganizationTypeArgs = {
  input?: InputMaybe<InputOrganiztionType>
  orgTypeId: Scalars['ID']
}

export type MutationUpdatePermissionArgs = {
  permissionList?: InputMaybe<Array<InputMaybe<InputPermissionForm>>>
  roleKey: Scalars['String']
}

export type MutationUpdateResourceAdminArgs = {
  adminInput?: InputMaybe<InputUpdateProfile>
  userId: Scalars['ID']
}

export type MutationUpdateResourceAdminPermissionArgs = {
  appKey: Scalars['String']
  permissionList?: InputMaybe<Array<InputMaybe<InputPermissionForm>>>
  roleKey: Scalars['String']
}

export type MutationUpdateResourceOwnerPermissionArgs = {
  appKey: Scalars['String']
  permissionList?: InputMaybe<Array<InputMaybe<InputPermissionForm>>>
  roleKey: Scalars['String']
}

export type MutationUpdateResourceOwnerProfileArgs = {
  input?: InputMaybe<InputUpdateProfile>
  resourceOwnerId: Scalars['ID']
}

export type MutationUpdateRoleArgs = {
  roleInput?: InputMaybe<InputRoleFrom>
  roleKey: Scalars['String']
}

export type MutationUpdateSecretKeyArgs = {
  credentialKey: Scalars['String']
  newSecretKey: Scalars['String']
}

export type MutationUpdateThemeArgs = {
  input?: InputMaybe<InputThemeForm>
}

export type MutationUpdateVersionControlArgs = {
  attribute?: InputMaybe<Scalars['JSON']>
  versionKey?: InputMaybe<Scalars['String']>
}

export type MutationVerifyEmailArgs = {
  input?: InputMaybe<InputVerifyEmail>
}

export type MutationVerifyEmailGlobalArgs = {
  input?: InputMaybe<InputVerifyEmail>
}

export type MutationVerifyEmailOrganizationArgs = {
  email: Scalars['String']
  verifyToken?: InputMaybe<Scalars['String']>
}

export type MutationVerifyInviteOrganizationArgs = {
  code: Scalars['String']
}

export type MutationVerifyOtpArgs = {
  otpCode: Scalars['String']
  phoneNumber: Scalars['String']
}

export type MutationVerifyPasswordEmailArgs = {
  input?: InputMaybe<InputVerifyPasswordEmail>
}

export type MutationVerifyPhoneNumberUserArgs = {
  phoneNumber: Scalars['String']
}

export type OrganizationList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeOrganizationResponse>>>
}

export type Query = {
  _dummy: Maybe<Scalars['String']>
  exportUser: Maybe<TypeTypeRolePermissionUserList>
  generateKey: Maybe<TypeGenerateKeyResponse>
  generateOrgTokenRef: Maybe<TypeOrgTokenRef>
  getAllOrganization: Maybe<OrganizationList>
  getAppByCredential: Maybe<GetAppCredentialHost>
  getAppByEmail: Maybe<GetAppByEmail>
  getAppService: Maybe<TypeAppServiceList>
  getAppTheme: Maybe<TypeThemeResponseList>
  getCategory: Maybe<TypeCategoryList>
  getConfig: Maybe<TypeConfigList>
  getCredential: Maybe<TypeCredentialList>
  getCredentialByHost: Maybe<GetAppCredentialHost>
  getCustomMenu: Maybe<TypeCustomMenuList>
  getDataRunningNumber: Maybe<TypeRunningNumberList>
  getDataSecurityRole: Maybe<TypeDataSecurityList>
  getGateway: Maybe<TypeGateway>
  getInviteOrgRole: Maybe<TypeAppRoleList>
  getInviteRole: Maybe<TypeAppRoleList>
  getInviteUserOrganization: Maybe<TypeInviteList>
  getLocaleText: Maybe<TypeLocaleTextList>
  getLog: Maybe<TypeLogList>
  getMasterData: Maybe<TypeMasterDataList>
  getMemberOrganization: Maybe<TypeProfileList>
  getMyAppRole: Maybe<TypeMyOrgRole>
  getMyNotification: Maybe<TypeNotificationList>
  getMyOrgRole: Maybe<TypeMyOrgRole>
  getMyPermission: Maybe<TypeTypeRolePermissionUserList>
  getMyProfile: Maybe<TypeProfile>
  getMyRoleApprovalAttribute: Maybe<TypeRoleAprovalAttribute>
  getNotificationMessage: Maybe<TypeNotificationMessageList>
  getNotificationTemplate: Maybe<TypeNotificationTemplateList>
  getOAuth: Maybe<TypeOAuth>
  getOrgAccessTokenWithRef: Maybe<TypeOrgAcceesToken>
  getOrgAdmin: Maybe<TypeProfileList>
  getOrgApprovalAttribute: Maybe<TypeOrganizationApprovalList>
  getOrgChildren: Maybe<TypeOrgChildren>
  getOrganization: Maybe<OrganizationList>
  getOrganizationApproval: Maybe<OrganizationList>
  getOrganizationByCoordinates: Maybe<OrganizationList>
  getOrganizationByName: Maybe<OrganizationList>
  getOrganizationLabel: Maybe<TypeOrganizationLabelList>
  getOrganizationType: Maybe<TypeOrganizationTypeList>
  getPermissionRole: Maybe<TypePermissionList>
  getProfile: Maybe<TypeProfileList>
  getPublicAppConfig: Maybe<TypeConfigList>
  getPublicProfile: Maybe<TypeProfileList>
  getResourceAdmin: Maybe<TypeProfileList>
  getResourceAdminPermission: Maybe<TypeTypeRolePermissionUserList>
  getResourceAdminRoleApprovalAttribute: Maybe<TypeRoleAprovalAttributeList>
  getResourceOwnerPermission: Maybe<TypeTypeRolePermissionUserList>
  getResourceOwnerProfile: Maybe<TypeProfileList>
  getResourceOwnerRoleApprovalAttribute: Maybe<TypeRoleAprovalAttributeList>
  getRole: Maybe<TypeAppRoleList>
  getRoleRegisable: Maybe<TypeAppRoleList>
  getS3GetObjectSignedUrl: Maybe<TypeS3SignedUrl>
  getS3PutObjectSignedUrl: Maybe<TypeS3SignedUrl>
  getSecretAppConfig: Maybe<TypeConfigList>
  getService: Maybe<TypeServiceList>
  getServiceFeature: Maybe<TypeFeatureList>
  getServiceInfo: Maybe<TypeServiceInfo>
  getTokenAuthCode: Maybe<TypeLogin>
  getVersionControl: Maybe<TypeVersionControlList>
  otherFields: Scalars['Boolean']
  webhookRunTaskAppNotification: Maybe<TypeWebhook>
  webhookRunTaskEmail: Maybe<TypeWebhook>
}

export type QueryGenerateKeyArgs = {
  capitalization?: InputMaybe<EnumGenerateKeyCapitalization>
  charset?: InputMaybe<Scalars['String']>
  length?: InputMaybe<Scalars['Int']>
  readable?: InputMaybe<Scalars['Boolean']>
}

export type QueryGenerateOrgTokenRefArgs = {
  orgKey: Scalars['String']
}

export type QueryGetAllOrganizationArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetAppByCredentialArgs = {
  credentialKey: Scalars['String']
}

export type QueryGetAppByEmailArgs = {
  email: Scalars['String']
}

export type QueryGetAppThemeArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetCategoryArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetConfigArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetCredentialByHostArgs = {
  host: Scalars['String']
}

export type QueryGetCustomMenuArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetDataRunningNumberArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetDataSecurityRoleArgs = {
  roleKey: Scalars['String']
}

export type QueryGetGatewayArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetInviteRoleArgs = {
  orgKey: Scalars['String']
}

export type QueryGetInviteUserOrganizationArgs = {
  input?: InputMaybe<InputFindData>
  orgKey: Scalars['String']
}

export type QueryGetLocaleTextArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetLogArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetMasterDataArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetMemberOrganizationArgs = {
  input?: InputMaybe<InputFindData>
  orgKey: Scalars['String']
}

export type QueryGetMyNotificationArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetMyOrgRoleArgs = {
  orgKey: Scalars['String']
}

export type QueryGetMyPermissionArgs = {
  orgKey?: InputMaybe<Scalars['String']>
}

export type QueryGetNotificationMessageArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetNotificationTemplateArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetOrgAccessTokenWithRefArgs = {
  tokenRef: Scalars['String']
}

export type QueryGetOrgAdminArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetOrgApprovalAttributeArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetOrgChildrenArgs = {
  orgKey?: InputMaybe<Scalars['String']>
}

export type QueryGetOrganizationArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetOrganizationApprovalArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetOrganizationByCoordinatesArgs = {
  coordinates: Array<InputMaybe<Scalars['Number']>>
  distance: Scalars['Number']
  limit?: InputMaybe<Scalars['Number']>
}

export type QueryGetOrganizationByNameArgs = {
  input?: InputMaybe<InputFindData>
  name?: InputMaybe<Scalars['String']>
}

export type QueryGetOrganizationLabelArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetOrganizationTypeArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetPermissionRoleArgs = {
  roleKey: Scalars['String']
}

export type QueryGetProfileArgs = {
  userIdList: Array<Scalars['ID']>
}

export type QueryGetPublicAppConfigArgs = {
  configKeyList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryGetPublicProfileArgs = {
  find?: InputMaybe<InputFindData>
  userIdList: Array<InputMaybe<Scalars['ID']>>
}

export type QueryGetResourceAdminArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetResourceAdminPermissionArgs = {
  roleKeyList?: InputMaybe<Array<Scalars['String']>>
}

export type QueryGetResourceAdminRoleApprovalAttributeArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetResourceOwnerPermissionArgs = {
  roleKeyList?: InputMaybe<Array<Scalars['String']>>
}

export type QueryGetResourceOwnerProfileArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryGetResourceOwnerRoleApprovalAttributeArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetRoleRegisableArgs = {
  roleKey?: InputMaybe<Scalars['String']>
}

export type QueryGetS3GetObjectSignedUrlArgs = {
  fileKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryGetS3PutObjectSignedUrlArgs = {
  inputs?: InputMaybe<Array<InputMaybe<InputS3PutObjectSignedUrl>>>
  userId?: InputMaybe<Scalars['ID']>
}

export type QueryGetSecretAppConfigArgs = {
  configKeyList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryGetServiceArgs = {
  input?: InputMaybe<InputFindData>
}

export type QueryGetServiceInfoArgs = {
  serviceKey: Scalars['String']
}

export type QueryGetTokenAuthCodeArgs = {
  code: Scalars['String']
}

export type QueryGetVersionControlArgs = {
  find?: InputMaybe<InputFindData>
}

export type QueryWebhookRunTaskAppNotificationArgs = {
  secretKey: Scalars['String']
}

export type QueryWebhookRunTaskEmailArgs = {
  secretKey: Scalars['String']
}

export type Subscription = {
  _dummy: Maybe<Scalars['String']>
  getMyNotification: Maybe<TypeNotificationList>
}

export type SubscriptionGetMyNotificationArgs = {
  find?: InputMaybe<InputFindData>
}

export type TypeApp = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeAppSchema>
}

export type TypeAppAcceesToken = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeAppAcceesTokenPayload>
}

export type TypeAppAcceesTokenPayload = {
  app: Maybe<TypeOrganizationResponse>
  profile: Maybe<TypeUserProfile>
  token: Maybe<TypeAppAcceesTokenPayloadToken>
}

export type TypeAppAcceesTokenPayloadToken = {
  appAccessToken: Maybe<Scalars['String']>
  appRefreshToken: Maybe<Scalars['String']>
}

export type TypeAppCredential = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeAppCredentialPayload>>>
}

export type TypeAppCredentialPayload = {
  app: Maybe<TypeAppSchema>
  credential: Maybe<TypeCredentialSchema>
}

export type TypeAppFeatureKey = {
  _id: Maybe<Scalars['ID']>
  description: Maybe<Scalars['String']>
  enable: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
  serviceId: Maybe<Scalars['ID']>
}

export type TypeAppList = {
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeAppSchema>>>
}

export type TypeAppRole = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeAppRolePayload>
}

export type TypeAppRoleList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeAppRoleListPayload>
}

export type TypeAppRoleListPayload = {
  app: Maybe<TypeAppSchema>
  roleList: Maybe<Array<Maybe<TypeRole>>>
}

export type TypeAppRolePayload = {
  app: Maybe<TypeAppSchema>
  roleList: Maybe<Array<Maybe<TypeRole>>>
  serviceList: Maybe<Array<Maybe<TypeServiceListPayload>>>
}

export type TypeAppSchema = {
  appKey: Maybe<Scalars['String']>
  attribute: Maybe<Scalars['JSON']>
  configurationList: Maybe<Array<Maybe<TypeConfigGlobal>>>
  contactEmailList: Maybe<Array<Maybe<Scalars['String']>>>
  featureList: Maybe<Array<Maybe<TypeFeatureSchema>>>
  name: Maybe<Scalars['String']>
  orgFieldList: Maybe<Array<Maybe<Scalars['JSON']>>>
  roleList: Maybe<Array<Maybe<Scalars['JSON']>>>
  status: Maybe<EnumAppStatus>
}

export type TypeAppService = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeAppServicePayload>
}

export type TypeAppServiceList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeAppServiceListPayload>
}

export type TypeAppServiceListPayload = {
  app: Maybe<TypeAppSchema>
  serviceList: Maybe<Array<Maybe<TypeService>>>
}

export type TypeAppServicePayload = {
  app: Maybe<TypeAppSchema>
  service: Maybe<TypeService>
  syncStatus: Maybe<Scalars['String']>
}

export type TypeAttribute = {
  _id: Maybe<Scalars['ID']>
  key: Maybe<Scalars['String']>
  value: Maybe<Scalars['String']>
}

export type TypeAuthCode = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeAuthCodePayload>
}

export type TypeAuthCodePayload = {
  authType: Maybe<Scalars['String']>
  code: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  redirectUrl: Maybe<Scalars['String']>
}

export type TypeAws = {
  _id: Maybe<Scalars['ID']>
  accessKey: Maybe<Scalars['String']>
  bucketName: Maybe<Scalars['String']>
  cloudWatchEnable: Maybe<Scalars['Boolean']>
  endpoint: Maybe<Scalars['String']>
  secretKey: Maybe<Scalars['String']>
}

export type TypeCategory = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeCategorySchema>
}

export type TypeCategoryList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeCategorySchema>>>
}

export type TypeCategorySchema = {
  _id: Maybe<Scalars['ID']>
  createdAt: Maybe<Scalars['Date']>
  createdBy: Maybe<TypeUserProfile>
  name: Maybe<Scalars['String']>
  status: Maybe<EnumCategoryStatus>
  updatedAt: Maybe<Scalars['Date']>
  updatedBy: Maybe<TypeUserProfile>
}

export type TypeCheckVerifyEmail = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  verifyStatus: Maybe<Scalars['String']>
}

export type TypeConfig = {
  configKey: Maybe<Scalars['String']>
  configName: Maybe<Scalars['String']>
  isGlobal: Maybe<Scalars['Boolean']>
  privacy: Maybe<EnumConfigPrivacy>
  relatedConfig: Maybe<Array<Maybe<Scalars['String']>>>
  value: Maybe<Scalars['JSON']>
}

export type TypeConfigGlobal = {
  configKey: Maybe<Scalars['String']>
  configName: Maybe<Scalars['String']>
  isGlobal: Maybe<Scalars['Boolean']>
  relatedConfig: Maybe<Array<Maybe<Scalars['String']>>>
  value: Maybe<TypeValueConfigGlobal>
}

export type TypeConfigList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeConfig>>>
}

export type TypeCredential = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeCredentialPayload>
}

export type TypeCredentialList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeCredentialListPayload>
}

export type TypeCredentialListPayload = {
  app: Maybe<TypeAppSchema>
  credentialList: Maybe<Array<Maybe<TypeCredentialSchema>>>
}

export type TypeCredentialPayload = {
  credential: Maybe<TypeCredentialSchema>
  serviceList: Maybe<Array<Maybe<TypeServiceListPayload>>>
}

export type TypeCredentialSchema = {
  credentialKey: Maybe<Scalars['String']>
  expiration: Maybe<TypeExpiration>
  hostList: Maybe<Array<Maybe<Scalars['String']>>>
  isAdminCredential: Maybe<EnumIs>
  name: Maybe<Scalars['String']>
  secretKey: Maybe<Scalars['String']>
  status: Maybe<EnumAppStatus>
  type: Maybe<EnumCredentialType>
}

export type TypeCustomMenu = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeSchemaCustomMenu>
}

export type TypeCustomMenuList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeSchemaCustomMenu>>>
}

export type TypeDataSecurityList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeRoleDataSecurity>>>
}

export type TypeEmail = {
  value: Maybe<Scalars['String']>
  verifyStatus: Maybe<EnumVerifyStatus>
}

export type TypeEmailRegister = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeUserProfile>
}

export type TypeEndpoinService = {
  resourceAdmin: Maybe<Scalars['String']>
  resourceOwner: Maybe<Scalars['String']>
  system: Maybe<Scalars['String']>
}

export type TypeEndpoinServiceType = {
  gql: Maybe<TypeEndpoinService>
  rest: Maybe<TypeEndpoinService>
}

export type TypeExpiration = {
  access: Maybe<Scalars['String']>
  refresh: Maybe<Scalars['String']>
}

export type TypeFeatureKey = {
  _id: Maybe<Scalars['ID']>
  description: Maybe<Scalars['String']>
  enable: Maybe<Scalars['Boolean']>
  key: Maybe<Scalars['String']>
}

export type TypeFeatureList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeFeatureListPayload>
}

export type TypeFeatureListPayload = {
  app: Maybe<TypeAppSchema>
  featureList: Maybe<Array<Maybe<TypeFeatureSchema>>>
}

export type TypeFeatureSchema = {
  config: Maybe<Scalars['JSON']>
  enable: Maybe<Scalars['Boolean']>
  featureKey: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  requiredFeatureKeyList: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TypeGateway = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeSchemaGateway>>>
}

export type TypeGatewayList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeSchemaGateway>
}

export type TypeGenerateKey = {
  key: Maybe<Scalars['String']>
}

export type TypeGenerateKeyResponse = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeGenerateKey>
}

export type TypeGenerateRunningNumber = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeRunningNumberNo>
}

export type TypeHello = {
  message: Maybe<Scalars['String']>
}

export type TypeInvite = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeSchemaInvite>
}

export type TypeInviteList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeSchemaInvite>>>
}

export type TypeJson = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Scalars['JSON']>
}

export type TypeKey = {
  _id: Maybe<Scalars['ID']>
  code: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  value: Maybe<Scalars['String']>
}

export type TypeLine = {
  login: Maybe<TypeLineLogin>
  notify: Maybe<TypeNotify>
}

export type TypeLineLogin = {
  _id: Maybe<Scalars['ID']>
  clientId: Maybe<Scalars['String']>
  clientSecret: Maybe<Scalars['String']>
  redirectUri: Maybe<Scalars['String']>
}

export type TypeLocaleText = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeLocaleTextResponse>
}

export type TypeLocaleTextList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeLocaleTextResponse>>>
}

export type TypeLocaleTextResponse = {
  attribute: Maybe<Scalars['JSON']>
  dataKey: Maybe<Scalars['String']>
  locale: Maybe<Scalars['String']>
  orgKey: Maybe<Scalars['String']>
  text: Maybe<Scalars['String']>
}

export type TypeLogin = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeLoginPayload>
}

export type TypeLoginPayload = {
  authType: Maybe<EnumAuthType>
  code: Maybe<Scalars['String']>
  credentialKey: Maybe<Scalars['String']>
  isFirstLogin: Maybe<Scalars['Boolean']>
  profile: Maybe<TypeUserProfile>
  redirectUrl: Maybe<Scalars['String']>
  token: Maybe<TypeToken>
}

export type TypeLogout = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeUserProfile>
}

export type TypeLogList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeLogSchema>>>
}

export type TypeLogSchema = {
  action: Maybe<Scalars['String']>
  actor: Maybe<Scalars['JSON']>
  createdAt: Maybe<Scalars['Date']>
  eventKey: Maybe<Scalars['String']>
  eventName: Maybe<Scalars['String']>
  ipAddress: Maybe<Scalars['String']>
  isSystemLog: Maybe<Scalars['Boolean']>
  message: Maybe<Scalars['String']>
  nextData: Maybe<Scalars['JSON']>
  prevData: Maybe<Scalars['JSON']>
  service: Maybe<Scalars['JSON']>
  updatedAt: Maybe<Scalars['Date']>
  userAgent: Maybe<Scalars['String']>
}

export type TypeMasterData = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeMasterDataResponse>
}

export type TypeMasterDataList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeMasterDataResponse>>>
}

export type TypeMasterDataResponse = {
  attribute: Maybe<Scalars['JSON']>
  dataKey: Maybe<Scalars['String']>
  locale: Maybe<Scalars['String']>
  parentKey: Maybe<Scalars['String']>
  searchable: Maybe<EnumIs>
  text: Maybe<Scalars['String']>
}

export type TypeMessage = {
  message: Maybe<Scalars['String']>
  success: Maybe<Scalars['Boolean']>
}

export type TypeMyOrgRole = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeRole>>>
}

export type TypeName = {
  name: Maybe<Scalars['String']>
  name_en: Maybe<Scalars['String']>
}

export type TypeNotification = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeNotificationSchema>
}

export type TypeNotificationDataKey = {
  dataKey: Maybe<Scalars['String']>
  defaultValue: Maybe<Scalars['String']>
  isRequired: Maybe<EnumNotificationIsRequired>
}

export type TypeNotificationList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeNotificationSchema>>>
  unread: Maybe<Scalars['Number']>
}

export type TypeNotificationMessage = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeNotificationMessageSchema>
}

export type TypeNotificationMessageCount = {
  totalSuccess: Maybe<Scalars['Number']>
}

export type TypeNotificationMessageCountPayload = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeNotificationMessageCount>
}

export type TypeNotificationMessageList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeNotificationMessageSchema>>>
}

export type TypeNotificationMessageSchema = {
  _id: Maybe<Scalars['ID']>
  channel: Maybe<TypeNotificationMessageSchemaChannel>
  content: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['Date']>
  displayTo: Maybe<Scalars['String']>
  from: Maybe<Scalars['String']>
  orgKey: Maybe<Scalars['String']>
  sendAt: Maybe<Scalars['Date']>
  sendType: Maybe<EnumNotificationMessageSendtype>
  status: Maybe<EnumNotificationMessageStatus>
  title: Maybe<Scalars['String']>
  to: Maybe<Scalars['String']>
  updatedAt: Maybe<Scalars['Date']>
}

export type TypeNotificationMessageSchemaChannel = {
  condition: Maybe<EnumNotificationMessageChannelCondition>
  key: Maybe<EnumNotificationMessageChannelKey>
}

export type TypeNotificationSchema = {
  _id: Maybe<Scalars['ID']>
  attribute: Maybe<Scalars['JSON']>
  content: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['Date']>
  from: Maybe<Scalars['String']>
  icon: Maybe<Scalars['String']>
  read: Maybe<EnumNotificationRead>
  sendAt: Maybe<Scalars['Date']>
  title: Maybe<Scalars['String']>
  to: Maybe<Scalars['String']>
  type: Maybe<EnumNotificationUserType>
  updatedAt: Maybe<Scalars['Date']>
}

export type TypeNotificationTemplate = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeScmemaNotificationTemplate>
}

export type TypeNotificationTemplateList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeScmemaNotificationTemplate>>>
}

export type TypeNotify = {
  _id: Maybe<Scalars['ID']>
  isSendNotify: Maybe<Scalars['Boolean']>
  notificationDisabled: Maybe<Scalars['Boolean']>
  notifyToken: Maybe<Scalars['String']>
}

export type TypeNumberAttribute = {
  _id: Maybe<Scalars['ID']>
  key: Maybe<Scalars['String']>
  value: Maybe<Scalars['Number']>
}

export type TypeOk = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
}

export type TypeOrganization = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeOrganizationResponse>
}

export type TypeOrganizationApproval = {
  approvalAttribute: Maybe<Scalars['JSON']>
  note: Maybe<Scalars['String']>
  organization: Maybe<TypeOrganizationResponse>
  status: Maybe<EnumOrganizationApprovalStatus>
}

export type TypeOrganizationApprovalList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeOrganizationApproval>>>
}

export type TypeOrganizationApprovalResponse = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeOrganizationApproval>
}

export type TypeOrganizationContactEmail = {
  value: Maybe<Scalars['String']>
  verifyStatus: Maybe<Scalars['String']>
}

export type TypeOrganizationLabel = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeOrganizationLabelSchema>
}

export type TypeOrganizationLabelList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeOrganizationLabelSchema>>>
}

export type TypeOrganizationLabelSchema = {
  _id: Maybe<Scalars['ID']>
  createdAt: Maybe<Scalars['Date']>
  createdBy: Maybe<TypeUserProfile>
  orgLabelDescription: Maybe<Scalars['String']>
  orgLabelIcon: Maybe<Scalars['String']>
  orgLabelName: Maybe<Scalars['String']>
  orgLabelStatus: Maybe<EnumOrganizationLabelStatus>
  orgLabelTitle: Maybe<Scalars['String']>
  updatedAt: Maybe<Scalars['Date']>
  updatedBy: Maybe<TypeUserProfile>
}

export type TypeOrganizationLocaleList = {
  dataKey: Maybe<Scalars['String']>
  locale: Maybe<Scalars['JSON']>
  masterKey: Maybe<Scalars['String']>
}

export type TypeOrganizationLocation = {
  coordinates: Maybe<Array<Maybe<Scalars['Number']>>>
  type: Maybe<Scalars['String']>
}

export type TypeOrganizationMasterDetailList = {
  dataKey: Maybe<Scalars['String']>
  masterData: Maybe<Scalars['JSON']>
  masterKey: Maybe<Scalars['String']>
}

export type TypeOrganizationResponse = {
  address: Maybe<Scalars['JSON']>
  attribute: Maybe<Scalars['JSON']>
  categoryList: Maybe<Array<Maybe<TypeCategorySchema>>>
  contactEmailList: Maybe<Array<Maybe<Scalars['String']>>>
  contactName: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['Date']>
  location: Maybe<TypeOrganizationLocation>
  name: Maybe<Scalars['String']>
  orgKey: Maybe<Scalars['String']>
  organizationLabelList: Maybe<Array<Maybe<TypeOrganizationLabelSchema>>>
  organizationType: Maybe<TypeOrganizationTypeSchema>
  path: Maybe<Scalars['String']>
  status: Maybe<EnumOrganizationStatus>
  updatedAt: Maybe<Scalars['Date']>
}

export type TypeOrganizationTagList = {
  masterKey: Maybe<Scalars['String']>
  tagKey: Maybe<Scalars['String']>
}

export type TypeOrganizationType = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeOrganizationTypeSchema>
}

export type TypeOrganizationTypeList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeOrganizationTypeSchema>>>
}

export type TypeOrganizationTypeSchema = {
  _id: Maybe<Scalars['ID']>
  createdAt: Maybe<Scalars['Date']>
  createdBy: Maybe<TypeUserProfile>
  needApprove: Maybe<EnumIs>
  orgFieldList: Maybe<Array<Maybe<Scalars['JSON']>>>
  orgTypeKey: Maybe<Scalars['String']>
  orgTypeName: Maybe<Scalars['String']>
  orgTypeStatus: Maybe<EnumOrganizationTypeStatus>
  updatedAt: Maybe<Scalars['Date']>
  updatedBy: Maybe<TypeUserProfile>
}

export type TypeOrgAcceesToken = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeOrgAcceesTokenPayload>
}

export type TypeOrgAcceesTokenPayload = {
  organization: Maybe<TypeOrganizationResponse>
  profile: Maybe<TypeUserProfile>
  token: Maybe<TypeOrgAcceesTokenPayloadToken>
}

export type TypeOrgAcceesTokenPayloadToken = {
  orgAccessToken: Maybe<Scalars['String']>
  orgRefreshToken: Maybe<Scalars['String']>
}

export type TypeOrgChildren = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeOrgChildrenPayload>>>
}

export type TypeOrgChildrenPayload = {
  children: Maybe<Scalars['JSON']>
  key: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  orgKey: Maybe<Scalars['String']>
  path: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
}

export type TypeOrgTokenRef = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeOrgTokenRefPayload>
}

export type TypeOrgTokenRefPayload = {
  organization: Maybe<TypeOrganizationResponse>
  profile: Maybe<TypeUserProfile>
  ref: Maybe<Scalars['String']>
}

export type TypeOtp = {
  countryCode: Maybe<Scalars['String']>
  expire: Maybe<Scalars['String']>
  phoneNumber: Maybe<Scalars['Number']>
  ref: Maybe<Scalars['String']>
}

export type TypeOAuth = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeSchemaOAuth>
}

export type TypePagination = {
  limit: Maybe<Scalars['Int']>
  page: Maybe<Scalars['Int']>
  totalItems: Maybe<Scalars['Int']>
  totalPages: Maybe<Scalars['Int']>
}

export type TypePasswordEmail = {
  email: Maybe<Scalars['String']>
}

export type TypePasswordOtp = {
  countryCode: Scalars['String']
  phoneNumber: Scalars['Number']
}

export type TypePermisionOption = {
  _id: Maybe<Scalars['ID']>
  code: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  level: Maybe<Scalars['Number']>
}

export type TypePermissionList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeRolePermission>>>
}

export type TypePhone = {
  code: Maybe<Scalars['String']>
  value: Maybe<Scalars['Number']>
  verifyStatus: Maybe<EnumVerifyStatus>
}

export type TypeProfile = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeUserProfile>
}

export type TypeProfileList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeUserProfile>>>
}

export type TypeRefreshAccessToken = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeToken>
}

export type TypeRequestOtp = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeOtp>
}

export type TypeResetPasswordEmail = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypePasswordEmail>
}

export type TypeResponse = {
  message: Maybe<Scalars['String']>
  success: Maybe<Scalars['Boolean']>
}

export type TypeRole = {
  attribute: Maybe<Scalars['JSON']>
  isDefault: Maybe<EnumIs>
  isInvite: Maybe<EnumIs>
  isReplaceable: Maybe<EnumIs>
  name: Maybe<Scalars['String']>
  regisable: Maybe<EnumIs>
  roleKey: Maybe<Scalars['String']>
  roleType: Maybe<EnumLrleType>
  status: Maybe<EnumAppStatus>
  userField: Maybe<Array<Maybe<Scalars['JSON']>>>
}

export type TypeRoleAprovalAttribute = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeRoleAprovalAttributeSchema>
}

export type TypeRoleAprovalAttributeList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeRoleAprovalAttributeSchema>>>
}

export type TypeRoleAprovalAttributeRole = {
  _id: Maybe<Scalars['String']>
  approval: Maybe<EnumIs>
  approvalAttribute: Maybe<Scalars['JSON']>
  isDefault: Maybe<EnumIs>
  isReplaceable: Maybe<EnumIs>
  name: Maybe<Scalars['String']>
  needApproval: Maybe<EnumIs>
  note: Maybe<Scalars['String']>
  roleKey: Maybe<Scalars['String']>
  roleType: Maybe<Scalars['String']>
  status: Maybe<EnumRoleAprovalAttributeStstus>
}

export type TypeRoleAprovalAttributeSchema = {
  profile: Maybe<TypeUserProfile>
  roleList: Maybe<Array<Maybe<TypeRoleAprovalAttributeRole>>>
}

export type TypeRoleDataSecurity = {
  assign: Maybe<EnumDataSecurity>
  assignToParent: Maybe<EnumIs>
  create: Maybe<EnumDataSecurity>
  delete: Maybe<EnumDataSecurity>
  name: Maybe<Scalars['String']>
  read: Maybe<EnumDataSecurity>
  securityKey: Maybe<Scalars['String']>
  write: Maybe<EnumDataSecurity>
}

export type TypeRoleDataSecurityList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeRoleDataSecurityPayload>
}

export type TypeRoleDataSecurityPayload = {
  dataSecurityList: Maybe<Array<Maybe<TypeRoleDataSecurity>>>
  role: Maybe<TypeRole>
}

export type TypeRolePermission = {
  code: Maybe<Array<Maybe<Scalars['String']>>>
  name: Maybe<Scalars['String']>
  permissionKey: Maybe<Scalars['String']>
  scopeList: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TypeRolePermissionList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeRolePermissionPayload>
}

export type TypeRolePermissionPayload = {
  permissionList: Maybe<Array<Maybe<TypeRolePermission>>>
  role: Maybe<TypeRole>
}

export type TypeRolePermissionSchema = {
  code: Maybe<Array<Maybe<Scalars['String']>>>
  permissionKey: Maybe<Scalars['String']>
  scopeList: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TypeRunningNumber = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeSchemaRunningNumber>
}

export type TypeRunningNumberList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeSchemaRunningNumber>>>
}

export type TypeRunningNumberNo = {
  runningNo: Maybe<Scalars['String']>
}

export type TypeS3SignedUrl = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeSchemaS3SignedUrl>>>
}

export type TypeSchemaCustomMenu = {
  _id: Maybe<Scalars['String']>
  actionColor: Maybe<Scalars['String']>
  appKey: Maybe<Scalars['String']>
  icon: Maybe<Scalars['String']>
  menuKey: Maybe<Scalars['String']>
  permissionList: Maybe<Array<Maybe<TypeSchemaCustomMenuPermissionList>>>
  status: Maybe<EnumUsability>
  target: Maybe<Scalars['String']>
  title: Maybe<Scalars['JSON']>
  url: Maybe<Scalars['String']>
}

export type TypeSchemaCustomMenuPermissionList = {
  code: Maybe<Array<Maybe<Scalars['String']>>>
  permissionKey: Maybe<Scalars['String']>
}

export type TypeSchemaGateway = {
  _id: Maybe<Scalars['ID']>
  apiType: Maybe<Scalars['String']>
  destination: Maybe<Array<Maybe<Scalars['String']>>>
  endpont: Maybe<Scalars['String']>
  header: Maybe<Scalars['JSON']>
  key: Maybe<Scalars['String']>
  method: Maybe<EnumGatewayMethod>
  name: Maybe<Scalars['String']>
  queryString: Maybe<Scalars['JSON']>
  resType: Maybe<EnumGatewayResType>
  schema: Maybe<Scalars['JSON']>
  source: Maybe<Array<Maybe<Scalars['String']>>>
  status: Maybe<EnumGatewayStatus>
  userRole: Maybe<EnumGatewayUserRole>
  variable: Maybe<Scalars['JSON']>
}

export type TypeSchemaInvite = {
  _id: Maybe<Scalars['ID']>
  email: Maybe<Scalars['String']>
  organization: Maybe<TypeOrganizationResponse>
  registered: Maybe<EnumIs>
  roleKey: Maybe<Scalars['String']>
  status: Maybe<EnumInviteStatus>
  userId: Maybe<Scalars['String']>
}

export type TypeSchemaOAuth = {
  appKey: Maybe<Scalars['String']>
  loginStatus: Maybe<EnumUsability>
  reauthentication: Maybe<EnumUsability>
  redirectUrlList: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TypeSchemaRunningNumber = {
  attribute: Maybe<Scalars['JSON']>
  createdBy: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  runningKey: Maybe<Scalars['String']>
  runningNumberFormat: Maybe<Scalars['String']>
  updatedBy: Maybe<Scalars['String']>
}

export type TypeSchemaS3SignedUrl = {
  createdAt: Maybe<Scalars['Date']>
  fileKey: Maybe<Scalars['String']>
  filename: Maybe<Scalars['String']>
  publicUrl: Maybe<Scalars['String']>
  signedUrl: Maybe<Scalars['String']>
  updatedAt: Maybe<Scalars['Date']>
  user: Maybe<TypeUserProfile>
}

export type TypeSchemaWebhook = {
  count: Maybe<Scalars['Int']>
  total: Maybe<Scalars['Int']>
}

export type TypeScmemaNotificationTemplate = {
  channel: EnumNotificationChannel
  dataKeyList: Maybe<Array<Maybe<TypeNotificationDataKey>>>
  locale: Scalars['String']
  templateKey: Maybe<Scalars['String']>
  templateValue: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
}

export type TypeService = {
  adminPanelEndpoint: Maybe<Scalars['String']>
  adminPanelMetaDataUrl: Maybe<Scalars['String']>
  adminWidgetMetaDataUrl: Maybe<Scalars['String']>
  dockerImageTag: Maybe<Scalars['String']>
  endpoint: Maybe<TypeEndpoinServiceType>
  name: Maybe<Scalars['String']>
  permissionList: Maybe<Array<Maybe<TypeServicePermission>>>
  serviceKey: Maybe<Scalars['String']>
}

export type TypeServiceInfo = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeService>
}

export type TypeServiceList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeService>>>
}

export type TypeServiceListPayload = {
  service: Maybe<TypeService>
  syncStatus: Maybe<Scalars['String']>
}

export type TypeServicePermission = {
  defaultCode: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  permissionKey: Maybe<Scalars['String']>
  scopeList: Maybe<Array<Maybe<Scalars['String']>>>
  userType: Maybe<Scalars['String']>
}

export type TypeSetting = {
  _id: Maybe<Scalars['ID']>
  description: Maybe<Scalars['String']>
  key: Maybe<Scalars['String']>
  value: Maybe<Scalars['String']>
}

export type TypeSmtp = {
  host: Maybe<Scalars['String']>
  password: Maybe<Scalars['String']>
  port: Maybe<Scalars['Number']>
  securedConnection: Maybe<Scalars['Boolean']>
  senderEmail: Maybe<Scalars['String']>
  senderName: Maybe<Scalars['String']>
  username: Maybe<Scalars['String']>
}

export type TypeSyncApp = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeSyncAppPayload>>>
}

export type TypeSyncAppPayload = {
  app: Maybe<TypeApp>
  serviceList: Maybe<Array<Maybe<TypeServiceListPayload>>>
}

export type TypeSyncOrganization = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeSyncOrganizationPayload>>>
}

export type TypeSyncOrganizationPayload = {
  organization: Maybe<TypeOrganizationResponse>
  syncStatus: Maybe<Scalars['String']>
}

export type TypeSyncServiceInfo = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeSyncServiceInfoPayload>
}

export type TypeSyncServiceInfoPayload = {
  serviceList: Maybe<Array<Maybe<TypeServiceListPayload>>>
}

export type TypeTemplate = {
  _id: Maybe<Scalars['ID']>
  content: Maybe<Scalars['String']>
  keys: Maybe<Array<Maybe<TypeKey>>>
  name: Maybe<Scalars['String']>
}

export type TypeThemeForm = {
  appKey: Maybe<Scalars['String']>
  color: Maybe<Scalars['JSON']>
  credentialKey: Maybe<Scalars['String']>
  custom: Maybe<Scalars['JSON']>
  image: Maybe<Scalars['JSON']>
  isDefault: Maybe<EnumThemeDefaultStatus>
  isReplaceable: Maybe<EnumThemeReplaceableStatus>
  name: Maybe<Scalars['String']>
  status: Maybe<EnumThemeStatus>
  text: Maybe<Scalars['JSON']>
  themeKey: Maybe<Scalars['String']>
  themeType: Maybe<EnumThemeType>
}

export type TypeThemeFormColor = {
  navAuthBg: Maybe<Scalars['String']>
  navAuthText: Maybe<Scalars['String']>
  navBg: Maybe<Scalars['String']>
  navText: Maybe<Scalars['String']>
  primary: Maybe<Scalars['String']>
  secondary: Maybe<Scalars['String']>
  titleText: Maybe<Scalars['String']>
}

export type TypeThemeFormImage = {
  favIcon: Maybe<Scalars['String']>
  navAuthCollapsedLogo: Maybe<Scalars['String']>
  navAuthLogo: Maybe<Scalars['String']>
  navLogo: Maybe<Scalars['String']>
  webLogo: Maybe<Scalars['String']>
}

export type TypeThemeFormText = {
  description: Maybe<Scalars['String']>
  navTitle: Maybe<Scalars['String']>
  webTitle: Maybe<Scalars['String']>
}

export type TypeThemeResponse = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeThemeForm>
}

export type TypeThemeResponseList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeThemeForm>>>
}

export type TypeToken = {
  accessToken: Maybe<Scalars['String']>
  refreshToken: Maybe<Scalars['String']>
}

export type TypeTokenExpired = {
  accessTokenExpired: Maybe<Scalars['Number']>
  refreshTokenExpired: Maybe<Scalars['Number']>
}

export type TypeTypeRolePermissionUserList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<Array<Maybe<TypeRolePermissionSchema>>>
}

export type TypeUpdateConfig = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeUpdateConfigPayload>
}

export type TypeUpdateConfigPayload = {
  config: Maybe<TypeConfig>
  serviceList: Maybe<Array<Maybe<TypeServiceListPayload>>>
}

export type TypeUsernameRegister = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeUserProfile>
}

export type TypeUserPermission = {
  defaultOption: Maybe<Scalars['String']>
  permissionName: Maybe<Scalars['String']>
  permissionOptions: Maybe<Array<Maybe<TypePermisionOption>>>
}

export type TypeUserProfile = {
  _id: Maybe<Scalars['ID']>
  appleId: Maybe<Scalars['String']>
  attribute: Maybe<Scalars['JSON']>
  email: Maybe<Array<Maybe<TypeEmail>>>
  facebookId: Maybe<Scalars['String']>
  googleId: Maybe<Scalars['String']>
  lineId: Maybe<Scalars['String']>
  phone: Maybe<Array<Maybe<TypePhone>>>
  role: Maybe<TypeRole>
  setting: Maybe<Scalars['JSON']>
  username: Maybe<Scalars['String']>
}

export type TypeValueConfigGlobal = {
  apiKey: Maybe<Scalars['String']>
  bucket: Maybe<Scalars['String']>
  headers: Maybe<Scalars['JSON']>
  publicEndpoint: Maybe<Scalars['String']>
  serviceEndpoint: Maybe<Scalars['String']>
}

export type TypeVerifyEmail = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  verifyId: Maybe<Scalars['String']>
}

export type TypeVerifyPasswordOtp = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypePasswordOtp>
}

export type TypeVersionControl = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeVersionControlSchema>
}

export type TypeVersionControlList = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  pagination: Maybe<TypePagination>
  payload: Maybe<Array<Maybe<TypeVersionControlSchema>>>
}

export type TypeVersionControlSchema = {
  _id: Maybe<Scalars['String']>
  attribute: Maybe<Scalars['JSON']>
  versionKey: Maybe<Scalars['String']>
}

export type TypeWebhook = {
  code: Maybe<Scalars['String']>
  message: Maybe<Scalars['String']>
  payload: Maybe<TypeSchemaWebhook>
}

export type GenerateAuthCodeMutationVariables = Exact<{ [key: string]: never }>

export type GenerateAuthCodeMutation = { generateAuthCode: { payload: { code: string; credentialKey: string } } }

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetMyProfileQuery = {
  getMyProfile: {
    message: string
    code: string
    payload: { attribute: any; email: Array<{ value: string }>; phone: Array<{ code: string; value: any }> }
  }
}

export type GetTokenAuthCodeQueryVariables = Exact<{
  code: Scalars['String']
}>

export type GetTokenAuthCodeQuery = {
  getTokenAuthCode: { payload: { token: { accessToken: string; refreshToken: string } } }
}

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type RefreshAccessTokenMutation = {
  refreshAccessToken: { payload: { accessToken: string; refreshToken: string } }
}

export type UpdateMyProfileMutationVariables = Exact<{
  input?: InputMaybe<InputUpdateProfile>
}>

export type UpdateMyProfileMutation = {
  updateMyProfile: {
    message: string
    code: string
    payload: { attribute: any; email: Array<{ value: string }>; phone: Array<{ code: string; value: any }> }
  }
}

export declare const generateAuthCode: import('graphql').DocumentNode
export declare const getMyProfile: import('graphql').DocumentNode
export declare const getTokenAuthCode: import('graphql').DocumentNode
export declare const refreshAccessToken: import('graphql').DocumentNode
export declare const updateMyProfile: import('graphql').DocumentNode

export const GenerateAuthCodeDocument = gql`
  mutation generateAuthCode {
    generateAuthCode {
      payload {
        code
        credentialKey
      }
    }
  }
`
export type GenerateAuthCodeMutationFn = Apollo.MutationFunction<
  GenerateAuthCodeMutation,
  GenerateAuthCodeMutationVariables
>

/**
 * __useGenerateAuthCodeMutation__
 *
 * To run a mutation, you first call `useGenerateAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAuthCodeMutation, { data, loading, error }] = useGenerateAuthCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateAuthCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateAuthCodeMutation, GenerateAuthCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateAuthCodeMutation, GenerateAuthCodeMutationVariables>(
    GenerateAuthCodeDocument,
    options
  )
}
export type GenerateAuthCodeMutationHookResult = ReturnType<typeof useGenerateAuthCodeMutation>
export type GenerateAuthCodeMutationResult = Apollo.MutationResult<GenerateAuthCodeMutation>
export type GenerateAuthCodeMutationOptions = Apollo.BaseMutationOptions<
  GenerateAuthCodeMutation,
  GenerateAuthCodeMutationVariables
>
export const GetMyProfileDocument = gql`
  query getMyProfile {
    getMyProfile {
      message
      code
      payload {
        email {
          value
        }
        phone {
          code
          value
        }
        attribute
      }
    }
  }
`

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options)
}
export function useGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options)
}
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>
export type GetMyProfileQueryResult = Apollo.QueryResult<GetMyProfileQuery, GetMyProfileQueryVariables>
export const GetTokenAuthCodeDocument = gql`
  query getTokenAuthCode($code: String!) {
    getTokenAuthCode(code: $code) {
      payload {
        token {
          accessToken
          refreshToken
        }
      }
    }
  }
`

/**
 * __useGetTokenAuthCodeQuery__
 *
 * To run a query within a React component, call `useGetTokenAuthCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenAuthCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenAuthCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetTokenAuthCodeQuery(
  baseOptions: Apollo.QueryHookOptions<GetTokenAuthCodeQuery, GetTokenAuthCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTokenAuthCodeQuery, GetTokenAuthCodeQueryVariables>(GetTokenAuthCodeDocument, options)
}
export function useGetTokenAuthCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTokenAuthCodeQuery, GetTokenAuthCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTokenAuthCodeQuery, GetTokenAuthCodeQueryVariables>(GetTokenAuthCodeDocument, options)
}
export type GetTokenAuthCodeQueryHookResult = ReturnType<typeof useGetTokenAuthCodeQuery>
export type GetTokenAuthCodeLazyQueryHookResult = ReturnType<typeof useGetTokenAuthCodeLazyQuery>
export type GetTokenAuthCodeQueryResult = Apollo.QueryResult<GetTokenAuthCodeQuery, GetTokenAuthCodeQueryVariables>
export const RefreshAccessTokenDocument = gql`
  mutation refreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      payload {
        accessToken
        refreshToken
      }
    }
  }
`
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(
    RefreshAccessTokenDocument,
    options
  )
}
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>
export const UpdateMyProfileDocument = gql`
  mutation updateMyProfile($input: INPUT_UPDATE_PROFILE) {
    updateMyProfile(input: $input) {
      message
      code
      payload {
        email {
          value
        }
        phone {
          code
          value
        }
        attribute
      }
    }
  }
`
export type UpdateMyProfileMutationFn = Apollo.MutationFunction<
  UpdateMyProfileMutation,
  UpdateMyProfileMutationVariables
>

/**
 * __useUpdateMyProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyProfileMutation, { data, loading, error }] = useUpdateMyProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(UpdateMyProfileDocument, options)
}
export type UpdateMyProfileMutationHookResult = ReturnType<typeof useUpdateMyProfileMutation>
export type UpdateMyProfileMutationResult = Apollo.MutationResult<UpdateMyProfileMutation>
export type UpdateMyProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyProfileMutation,
  UpdateMyProfileMutationVariables
>
