import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { message } from 'antd'

import type {
  APIPayloadResponse,
  AuthType,
  GraphQLServiceMutationHook,
  LoginAPIPayload,
} from 'graphQL/graphQL-service-hook'

const MUTATION_DOCUMENT = loader('./loginFacebook.gql')

interface LoginFacebookData {
  loginFacebook: APIPayloadResponse<LoginAPIPayload>
}

interface LoginFacebookVars {
  facebookToken: string
  authType: AuthType
  redirectUrl?: string | null
}

const useLoginFacebook: GraphQLServiceMutationHook<LoginFacebookData, LoginFacebookVars> = (options) =>
  useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })

export default useLoginFacebook
