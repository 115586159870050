import React, { createContext, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { changeAntdTheme } from 'dynamic-antd-theme'
import { useTranslation } from 'react-i18next'

import FullScreenLoading from 'components/FullScreenLoading'

import useGetAppTheme from 'graphQL/useGetAppTheme'
import useGetAppByCredentialKey from 'graphQL/useGetAppByCredentialKey'

import useQueryString from 'hooks/useQueryString'

import { getAppDataFromStorage } from 'utils/app'
import { appLocalApp } from 'utils/localService'
import { authorizationKey } from 'config'
import apiPath from 'config/api'

import type { ThemeContextData } from './interface'
import type { AppThemeAPIPayload, ThemeColor } from 'graphQL/useGetAppTheme/interface'

const defaultThemeContextData: ThemeContextData = {
  admin: {
    appKey: '',
    custom: '',
    isDefault: 'YES',
    isReplaceable: 'NO',
    name: '',
    status: 'ACTIVE',
    image: null,
    color: null,
    text: null,
    themeKey: '',
    themeType: 'ADMIN',
    credentialKey: '',
  },
  collapsed: false,
  collapseHandler() {},
}

const ThemeContext = createContext<ThemeContextData>(defaultThemeContextData)

const ThemeProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('loading')

  const [admin, setAdminTheme] = useState<AppThemeAPIPayload>(defaultThemeContextData.admin)
  const [collapsed, setCollapsed] = useState(defaultThemeContextData.collapsed)

  const query = useQueryString()
  const credential = query.get(authorizationKey.credential)

  const app = getAppDataFromStorage()

  const credentialKey = credential || app?.credential?.credentialKey

  const appByCredentialQuery = useGetAppByCredentialKey({
    skip: credentialKey == null,
    variables: {
      credentialKey: credentialKey as string,
    },
    context: {
      uri: apiPath.core.admin,
      headers: {
        credentialKey,
      },
    },
    onCompleted(resp) {
      appLocalApp.set(JSON.stringify(resp.getAppByCredential.payload))
    },
  })

  const themeQuery = useGetAppTheme({
    skip: appByCredentialQuery.data == null,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    context: {
      uri: apiPath.core.systemAdmin,
      headers: {
        authorization: '',
        credentialKey,
        abcdefgTheme: 'adlhkajsglksadjd',
      },
    },
    onCompleted(resp) {
      onThemeHandler(resp.getAppTheme.payload[0])
    },
  })

  const onThemeHandler = (theme: AppThemeAPIPayload) => {
    setupTheme(theme)
    setAdminTheme(theme)
  }

  const collapseHandler = () => {
    setCollapsed(!collapsed)
  }

  const setupTheme = (theme: AppThemeAPIPayload) => {
    setupThemeColor(theme.color)

    if (theme.image) {
      const { favIcon } = theme.image
      const favEle = document.getElementById('favicon') as HTMLLinkElement
      if (favIcon && favEle) {
        favEle.href = favIcon
      }
    }
  }

  const setupThemeColor = (color: ThemeColor | null) => {
    const fallbackColor = {
      primary: '#006A2B',
      secondary: '#7E7E7E',
      text: '#000',
      navText: '#fff',
    }

    const primary = color?.primary || fallbackColor.primary
    const secondary = color?.secondary || fallbackColor.primary
    const text = color?.titleText || fallbackColor.text

    changeAntdTheme(primary)

    document.documentElement.style.setProperty('--app-primary', primary)
    document.documentElement.style.setProperty('--app-secondary', secondary)
    document.documentElement.style.setProperty('--app-titleText', text)
    document.documentElement.style.setProperty('--app-navBg', color?.navBg || primary)
    document.documentElement.style.setProperty('--app-navText', color?.navText || fallbackColor.navText)
    document.documentElement.style.setProperty('--app-navAuthBg', color?.navAuthBg || '#fff')
    document.documentElement.style.setProperty('--app-navAuthText', color?.navAuthText || primary)
  }

  return (
    <ThemeContext.Provider
      value={{
        admin,
        collapsed,
        collapseHandler,
      }}
    >
      {appByCredentialQuery.loading || themeQuery.loading ? (
        <FullScreenLoading>{t('theme')}</FullScreenLoading>
      ) : (
        <>
          <Helmet>
            <title>{admin.text?.webTitle}</title>
          </Helmet>

          {children}
        </>
      )}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider

export const useThemeContext = () => useContext(ThemeContext)
