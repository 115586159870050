import React, { useEffect, useState } from 'react'
import { message, Result } from 'antd'
import { useSearchParams } from 'react-router-dom'

import DefaultLayout from 'layouts/DefaultLayout'

import useVerifyEmail from 'graphQL/useVerifyEmail'

import LoadingIcon from 'components/LoadingIcon'

const VerifyEmailPage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState<boolean>()

  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')
  const email = searchParams.get('email')
  const credentialKey = searchParams.get('credential_key')

  const [verifyEmail, verifyEmailResp] = useVerifyEmail({
    context: {
      headers: {
        authorization: '',
        credentialKey,
      },
    },
    onCompleted() {
      setVerified(true)
      setLoading(false)
    },
    onError(error) {
      message.error(error.message)

      setVerified(false)
      setLoading(false)
    },
  })

  const status = verified ? 'success' : 'error'
  const title = verified ? 'Successfully Verified email.' : 'Email verify failed. Please try again later.'

  useEffect(() => {
    if (token == null || email == null || credentialKey == null) {
      return
    }

    verifyEmail({
      variables: {
        input: {
          email,
          verifyToken: token,
        },
      },
    })
  }, [token, email, verifyEmail, credentialKey])

  return (
    <DefaultLayout>
      {loading || verifyEmailResp.loading ? <LoadingIcon /> : <Result status={status} title={title} />}
    </DefaultLayout>
  )
}

export default VerifyEmailPage
