import React, { useState, useEffect } from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { parse } from 'query-string'

import FullScreenLoading from 'components/FullScreenLoading'

import useVerifyInviteOrganization from 'graphQL/useVerifyInviteOrganization'

import useQueryString from 'hooks/useQueryString'

import apiPath from 'config/api'
import { authorizationKey } from 'config'

import { paths } from 'setup/PageRouter'

import { routerTo } from 'helpers/utils'

import { EnumAuthType } from 'graphQL/generated/operations'

import type { ApolloError } from '@apollo/client'
import type { ResultResponse } from './interface'

const VerifyInviteOrganizationPage: React.FC = () => {
  const { t } = useTranslation('verifyInviteOrganization')

  const query = useQueryString()

  const [isInviteSuccess, setIsInviteSuccess] = useState(false)
  const [isInviteFailed, setIsInviteFailed] = useState(false)
  const [error, setError] = useState<ApolloError | undefined>()

  const navigate = useNavigate()

  const code = query.get(authorizationKey.code) || ''
  const credentialKey = query.get(authorizationKey.credential) || ''
  const redirectUrl = query.get(authorizationKey.redirectUrl)

  const [verifyInviteOrganization] = useVerifyInviteOrganization({
    onCompleted(resp) {
      if (resp.verifyInviteOrganization.payload.registered === 'YES') {
        setIsInviteSuccess(true)
        setIsInviteFailed(false)
        setError(undefined)
      } else {
        navigate({
          pathname: paths.register,
          search: `${query.toString()}&${authorizationKey.authType}=${EnumAuthType.AuthCode}`,
        })
      }
    },
    onError(error) {
      setError(error)
      setIsInviteSuccess(false)
      setIsInviteFailed(true)
    },
    context: {
      uri: apiPath.core.admin,
      headers: {
        authorizationKey: '',
        credentialKey,
      },
    },
  })

  const { status, subTitle, title } = useResult()

  useEffect(() => {
    if (code) {
      verifyInviteOrganization({
        variables: {
          code,
        },
      })
    } else {
      navigate(paths.signIn)
    }
  }, [code, navigate, verifyInviteOrganization])

  const extra =
    redirectUrl == null
      ? []
      : [
          <Button
            key="back-to-sign-in"
            type="primary"
            onClick={() => {
              navigate(
                routerTo(paths.credentialApplicationSignIn, {
                  query: {
                    ...parse(query.toString()),
                    [authorizationKey.authType]: EnumAuthType.AuthCode,
                  },
                })
              )
            }}
          >
            กลับไปหน้าเข้าสู่ระบบ
          </Button>,
        ]

  if (isInviteSuccess || isInviteFailed) {
    return (
      <VerifySuccessWrapper>
        <Result status={status} title={title} subTitle={subTitle} extra={extra} />
      </VerifySuccessWrapper>
    )
  }

  return <FullScreenLoading>{t('loading')}</FullScreenLoading>

  function useResult(): ResultResponse {
    if (isInviteSuccess) {
      return {
        status: 'success',
        title: t('success.title'),
        subTitle: t('success.subTitle'),
      }
    }

    return {
      status: 'error',
      title: t('failed.title'),
      subTitle: error?.message,
    }
  }
}

export default VerifyInviteOrganizationPage

const VerifySuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
