import { fallbackString } from 'helpers/formatter'

type APIEndpointKey = 'core'

interface APIConfigPath {
  systemAdmin: string
  admin: string
  client: string
}

type API = {
  [key in APIEndpointKey]: APIConfigPath
}

const apiPath: API = {
  core: {
    systemAdmin: fallbackString(process.env.REACT_APP_CORE_URL_SYSTEM_ADMIN),
    admin: fallbackString(process.env.REACT_APP_CORE_URL_ADMIN),
    client: fallbackString(process.env.REACT_APP_CORE_URL_CLIENT),
  },
}

export default apiPath
