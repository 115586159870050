import React from 'react'

import DefaultLayout from 'layouts/DefaultLayout'

import BackButton from 'components/BackButton'
import ForgotPasswordForm from 'components/ForgotPasswordForm'
import ResendConfirmationEmailForm from 'components/ResendConfirmationEmailForm'
import CredentialApplicationSignInCard from './CredentialApplicationSignInCard'

import type { CredentialApplicationSignInProps } from './interface'

const CredentialApplicationSignIn: React.FC<CredentialApplicationSignInProps> = ({
  signInPanelType,
  loginEmailLoading,
  onFinish,
  onSignInPanelTypeChange,
}) => {
  return (
    <DefaultLayout>
      {signInPanelType !== 'SignIn' && <BackButton onBack={() => onSignInPanelTypeChange('SignIn')} />}

      {renderCredentialSignInPanelByType()}
    </DefaultLayout>
  )

  function renderCredentialSignInPanelByType() {
    switch (signInPanelType) {
      case 'ResendEmail':
        return <ResendConfirmationEmailForm />
      case 'ResetPassword':
        return <ForgotPasswordForm />
      default:
        return (
          <CredentialApplicationSignInCard
            buttonLoading={loginEmailLoading}
            onFinish={onFinish}
            onSignInPanelTypeChange={onSignInPanelTypeChange}
          />
        )
    }
  }
}

export default CredentialApplicationSignIn
