import React from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import useAuth from 'contexts/useAuthContext'

import { useUpdateMyProfileMutation } from 'graphQL/generated/operations'

import useMasterData from 'hooks/useMasterData'

import ContentCard from 'components/ContentCard'
import DangerZone from 'components/Setting/DangerZone'
import PageTitle from 'components/PageTitle'
import LanguageAndRegionForm from './LanguageAndRegionForm'

import { removeUndefinedObjectData } from 'utils/object'

const Setting: React.FC = () => {
  const { t } = useTranslation('setting')

  const { user, setUser } = useAuth()

  const attribute = user?.attribute

  const { loading, masterDataList } = useMasterData({
    parentKeys: ['LANGUAGE', 'TIME_ZONE'],
    sortCondition: {
      attribute: 1,
    },
  })

  const [updateMyProfile, updateMyProfileResp] = useUpdateMyProfileMutation({
    onCompleted(resp) {
      message.success(t('updateSuccessText'))

      setUser(resp.updateMyProfile.payload)
    },
  })

  return (
    <AuthenticatedLayout>
      <PageTitle isRootPage title={t('title')} />
      <ContentCard title={t('cardTitle.languageAndRegion')} loading={loading}>
        {!loading && (
          <LanguageAndRegionForm
            loading={updateMyProfileResp.loading}
            languageAndRegionFormData={{
              ...attribute,
            }}
            languageList={masterDataList.LANGUAGE}
            timezoneList={masterDataList.TIME_ZONE}
            onFinish={(data) => {
              const filteredUndefinedData = removeUndefinedObjectData(data)

              updateMyProfile({
                variables: {
                  input: {
                    attribute: {
                      ...attribute,
                      ...filteredUndefinedData,
                    },
                  },
                },
              })
            }}
          />
        )}
      </ContentCard>

      <DangerZone />
    </AuthenticatedLayout>
  )
}

export default Setting
