import { gql } from '@apollo/client'

const DELETE_MY_USER = gql`
  mutation {
    deleteMyUser {
      payload {
        _id
      }
    }
  }
`

export default DELETE_MY_USER
