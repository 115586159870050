import React from 'react'
import { createPortal } from 'react-dom'
import styled, { createGlobalStyle } from 'styled-components'

import LoadingIcon from './LoadingIcon'

const FullScreenLoading: React.FC = ({ children }) => {
  return createPortal(
    <>
      <GlobalStyle />

      <FullScreenLoadingWrapper>
        <LoadingIcon>{children}</LoadingIcon>
      </FullScreenLoadingWrapper>
    </>,
    document.body
  )
}

export default FullScreenLoading

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const FullScreenLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: #fff;
`
