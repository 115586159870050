import jwtDecode from 'jwt-decode'

import type { TokenParam } from 'utils/user'
import type { TokenPayload } from './interface'

export function decodeToken<T = TokenPayload>(accessToken: TokenParam): T | undefined {
  if (!accessToken) return

  const decode = jwtDecode<T>(accessToken)

  return decode
}

export function checkTokenExpired(accessToken: TokenParam): boolean {
  let isExpired = true

  if (accessToken == null) {
    return isExpired
  }

  try {
    const accessTokenPayload = decodeToken(accessToken)

    if (!accessTokenPayload?.exp || Date.now() < accessTokenPayload.exp * 1000) isExpired = false

    return isExpired
  } catch {
    return isExpired
  }
}
