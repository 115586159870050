import React, { createContext, useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { useApolloClient } from '@apollo/client'

import FullScreenLoading from 'components/FullScreenLoading'

import { accessToken, clearStorage, refreshToken } from 'services/localStorage'

import { GetMyProfileDocument, GetMyProfileQuery, GetMyProfileQueryVariables } from 'graphQL/generated/operations'

import type { GetMyProfileData } from 'graphQL/queryResponseTypes'

export interface AuthToken {
  accessToken: string
  refreshToken: string
}

interface AuthContextData {
  token?: AuthToken
  user?: GetMyProfileData
  setUser: (user: GetMyProfileData) => void
  signIn: (authToken: AuthToken, callback?: VoidFunction) => void
  signOut: VoidFunction
}

const AuthContext = createContext<any>(null)

export const AuthProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('global')

  const client = useApolloClient()

  const storageToken = accessToken.get()

  const [authLoading, setAuthLoading] = useState(storageToken != null)
  const [token, setToken] = useState<AuthToken>()
  const [user, setUser] = useState<GetMyProfileData>()

  const getMyProfile = (callback?: VoidFunction) =>
    client
      .query<GetMyProfileQuery, GetMyProfileQueryVariables>({
        query: GetMyProfileDocument,
      })
      .then(({ data }) => {
        const user = data.getMyProfile.payload

        setUser(user)
        setAuthLoading(false)
      })
      .then(callback)
      .catch((error) => console.log(error.message))

  const signIn = (authToken: AuthToken, callback?: VoidFunction) => {
    setToken(authToken)

    accessToken.set(authToken.accessToken)
    refreshToken.set(authToken.refreshToken)

    getMyProfile(callback)
  }

  const signOut = () => {
    setUser(undefined)
    setToken(undefined)

    clearStorage()
    client.resetStore()
  }

  const loading = authLoading
  const value: AuthContextData = {
    token,
    user,
    setUser,
    signIn,
    signOut,
  }

  useEffectOnce(onFirstSignIn)

  return (
    <AuthContext.Provider value={value}>
      {loading ? <FullScreenLoading>{t('loadingText')}</FullScreenLoading> : children}
    </AuthContext.Provider>
  )

  function onFirstSignIn() {
    if (storageToken == null) {
      return
    }

    getMyProfile()
  }
}

export default function useAuth() {
  return useContext<AuthContextData>(AuthContext)
}
