import React, { useState } from 'react'
import { Button, List } from 'antd'
import { useNavigate } from 'react-router-dom'

import ConfirmationDeleteAccountModal from 'components/ConfirmationDeleteAccountModal'

import useDeleteMyUser from 'graphQL/useDeleteMyUser'

import { paths } from 'setup/PageRouter'

import type { DeleteAccountProps } from './interface'

const DeleteAccount: React.FC<DeleteAccountProps> = ({ t }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const navigate = useNavigate()

  const [deleteMyUser, deleteMyUserResp] = useDeleteMyUser({
    onCompleted() {
      navigate(paths.signOut)
    },
  })

  return (
    <>
      <List.Item actions={[renderDeleteAccountButton()]}>
        <List.Item.Meta
          title={<span className="bold">{t('deleteAccount.title')}</span>}
          description={t('deleteAccount.description')}
        />
      </List.Item>

      <ConfirmationDeleteAccountModal
        visible={modalVisible}
        loading={deleteMyUserResp.loading}
        onClose={() => setModalVisible(false)}
        onOk={() => {
          deleteMyUser()
        }}
      />
    </>
  )

  function renderDeleteAccountButton() {
    return (
      <Button type="primary" danger onClick={() => setModalVisible(true)}>
        {t('deleteAccount.button')}
      </Button>
    )
  }
}

export default DeleteAccount
