import React from 'react'
import styled from 'styled-components'
import { Divider, Space } from 'antd'

import signInBanner from 'assets/images/sign-in-banner.png'
import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'

interface LeftPanelSignInProps {
  title?: string
  subtitle?: string
  description?: string
  custom?: string
  extra?: React.ReactNode
}

const LeftPanelSignIn: React.FC<LeftPanelSignInProps> = ({ title, subtitle, description, extra, custom }) => {
  const theme = useThemeContext()

  return (
    <LeftPanelSignInWrapper bannerImage={theme.admin.image?.authRightBg || signInBanner}>
      <Space direction="vertical">
        {title && <AdminPanelTitle className="medium">{title}</AdminPanelTitle>}
        {subtitle && <AdminPanelSubtitle className="medium">{subtitle}</AdminPanelSubtitle>}
        {description && <AdminPanelDescription>{description}</AdminPanelDescription>}
        {custom && <AdminPanelCustom dangerouslySetInnerHTML={{ __html: custom }} />}
      </Space>

      {extra && (
        <>
          <Divider />
          {extra}
        </>
      )}
    </LeftPanelSignInWrapper>
  )
}

export default LeftPanelSignIn

const LeftPanelSignInWrapper = styled.div<{ bannerImage: string }>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 100px 200px 100px 100px;
  background-image: ${({ bannerImage }) => `url(${bannerImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(4, 15, 25, 0.8);
  overflow: auto;

  @media (max-width: 720px) {
    padding: 20px 20px 20px 20px;
    height: auto;
  }

  & > * {
    color: #fff;
  }
`
const AdminPanelTitle = styled.span`
  font-size: 72px;
  @media (max-width: 720px) {
    font-size: 36px;
  }
`

const AdminPanelSubtitle = styled.span`
  font-size: 36px;
  @media (max-width: 720px) {
    font-size: 24px;
  }
`

const AdminPanelDescription = styled.span`
  font-size: 24px;
  color: #ffd803;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`

const AdminPanelCustom = styled.span`
  font-size: 20px;
  margin-top: 1em;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`
