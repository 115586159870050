import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useLoginEmail from 'graphQL/useLoginEmail'

import CredentialApplicationSignIn from 'components/CredentialApplicationSignIn/CredentialApplicationSignIn'

import useAuth from 'contexts/useAuthContext'

import { appLocalApp } from 'utils/localService'

import useQueryString from 'hooks/useQueryString'

import { authorizationKey } from 'config'

import { paths } from 'setup/PageRouter'

import type { SignInPanelType } from 'components/CredentialApplicationSignIn/interface'

export interface ForgotPasswordData {
  email: string
}

const CredentialApplicationSignInPage: React.FC = () => {
  const navigate = useNavigate()

  const [signInPanelType, setSignInPanelType] = useState<SignInPanelType>('SignIn')

  const auth = useAuth()
  const query = useQueryString()
  const redirectUrl = query.get(authorizationKey.redirectUrl)

  const app = JSON.parse(appLocalApp.get() as string)

  const [loginEmail, loginEmailResp] = useLoginEmail({
    onCompleted(resp) {
      const { token, authType, credentialKey, code, redirectUrl } = resp.loginEmail.payload

      auth.signIn(token, () => {
        if (redirectUrl) {
          const callbackUrl = `${redirectUrl}?${authorizationKey.code}=${code}&${authorizationKey.authType}=${authType}&${authorizationKey.credential}=${credentialKey}`

          window.open(callbackUrl, '_self')
        } else {
          navigate(paths.general)
        }
      })
    },
  })

  return (
    <CredentialApplicationSignIn
      signInPanelType={signInPanelType}
      loginEmailLoading={loginEmailResp.loading}
      onSignInPanelTypeChange={(type) => setSignInPanelType(type)}
      onFinish={(data) => {
        loginEmail({
          variables: {
            ...data,
            authType: redirectUrl ? 'AUTH_CODE' : 'AUTH_TOKEN',
            redirectUrl,
          },
          context: {
            headers: {
              credentialKey: app?.credential?.credentialKey,
            },
          },
        })
      }}
    />
  )
}

export default CredentialApplicationSignInPage
