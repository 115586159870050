import { useMutation } from '@apollo/client'

import MUTATION_DOCUMENT from './resendRegisterEmail'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { ResendEmailAPIPayload } from './interface'

interface ResendRegisterEmailData {
  resendRegisterEmail: APIPayloadResponse<ResendEmailAPIPayload>
}

interface ResendRegisterEmailVars {
  email: string
}

const useResendRegisterEmail: GraphQLServiceMutationHook<ResendRegisterEmailData, ResendRegisterEmailVars> = (
  options
) => {
  return useMutation(MUTATION_DOCUMENT, {
    ...options,
  })
}

export default useResendRegisterEmail
