import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import BackButton from 'components/BackButton'
import FullWidthSpace from 'components/FullWidthSpace'
import LanguageSelector from 'components/LanguageSelector'

import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'

import { getAppDataFromStorage } from 'utils/app'

interface RightPanelSignInProps {
  copyright?: string
  onBack?: () => void
}

const RightPanelSignIn: React.FC<RightPanelSignInProps> = ({ copyright, onBack, children }) => {
  const { t } = useTranslation('signIn')

  const theme = useThemeContext()
  const app = getAppDataFromStorage()

  return (
    <>
      <RightPanelSignInWrapper>
        <LanguageContainer>
          <LanguageSelector />
        </LanguageContainer>

        {typeof onBack === 'function' && <BackButton onBack={onBack} />}

        {theme.admin.image?.authRightLogo ? (
          <ApplicationLogoSection>
            <img src={theme.admin.image?.authRightLogo} alt="application logo" />
          </ApplicationLogoSection>
        ) : (
          <ApplicationNameTitle>{app?.app?.name || 'Hooray.site'}</ApplicationNameTitle>
        )}

        <FullWidthSpace size={24} direction="vertical">
          {children}
        </FullWidthSpace>
        <div style={{ marginTop: 24, textAlign: 'center' }}>
          <FullWidthSpace size={16} direction="vertical">
            <CopyrightText>
              {t('copyrightText')} © {dayjs().format('YYYY')} {copyright}
            </CopyrightText>
          </FullWidthSpace>
        </div>
      </RightPanelSignInWrapper>
    </>
  )
}

export default RightPanelSignIn

const LanguageContainer = styled.div`
  position: absolute;
  right: 45px;
  top: 10px;
  @media (max-width: 768px) {
    right: 15px;
    top: 10px;
  }
`

const RightPanelSignInWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 80px 120px;
  overflow: auto;
  @media (max-width: 1500px) {
    margin: 0 auto;
    padding: 80px 50px;
    max-width: 960px;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    padding: 10px 16px;
  }
`

const ApplicationLogoSection = styled.div`
  display: block;
  margin: 0 auto;
  width: 192px;
  height: 192px;

  & > img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    margin-bottom: 40 px;
  }
`

const ApplicationNameTitle = styled.span`
  font-size: 45px;
  font-family: 'Poppins Bold', sans-serif;
  color: var(--app-primary);
  text-align: center;
  margin-bottom: 24px;
`

const CopyrightText = styled.span`
  color: #7e7e7e;
  text-align: center;
`
