import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FullScreenLoading from 'components/FullScreenLoading'

import useQueryString from 'hooks/useQueryString'

import { useGetTokenAuthCodeQuery } from 'graphQL/generated/operations'

import { accessToken, refreshToken } from 'services/localStorage'

import { paths } from 'setup/PageRouter'

import { authorizationKey } from 'config'

const AuthPage: React.FC = () => {
  const query = useQueryString()

  const { t } = useTranslation('loading')

  const authCode = query.get(authorizationKey.code) as string
  const credentialKey = query.get(authorizationKey.credential) as string

  useGetTokenAuthCodeQuery({
    skip: authCode == null,
    variables: {
      code: authCode,
    },
    context: {
      headers: {
        credentialKey,
      },
    },
    onCompleted({ getTokenAuthCode }) {
      const token = getTokenAuthCode.payload.token

      accessToken.set(token.accessToken)
      refreshToken.set(token.refreshToken)

      window.location.href = `/?${query.toString()}&${authorizationKey.authType}=AUTH_CODE`
    },
    onError(error) {
      console.log(error.message)

      window.location.href = `/?${query.toString()}&error=true&${authorizationKey.authType}=AUTH_CODE`
    },
  })

  if (authCode == null) {
    return <Navigate to={paths.root} />
  }

  return <FullScreenLoading>{t('signIn')}</FullScreenLoading>
}

export default AuthPage
