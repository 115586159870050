export const s3Pathname = 'thamturakit-public/hooray-account/'

export const liffEndpointUrl = process.env.REACT_APP_LIFF_ENDPOINT_URL || 'https://account-dev.hooray.site'
export const liffId = process.env.REACT_APP_LIFF_ID || '1656404229-QjGOyxL3'

export const authorizationKey = {
  clientID: 'client_id',
  credential: 'credential_key',
  authType: 'auth_type',
  redirectUrl: 'redirect_url',
  code: 'code',
  error: 'error',
}

export const queryKey = {
  channelType: 'channel_type',
}

export const thirdPartyChannelType = ['FACEBOOK', 'GOOGLE', 'LINE']

export const allowFileExtensions = '.jpg, .jpeg, .png'

/**
 * Will get from the application config instead ASAP
 */
export const facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID || ''
export const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
