import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'

import EmailForm from 'components/EmailForm'

import useResendRegisterEmail from 'graphQL/useResendRegisterEmail'

import { getAppDataFromStorage } from 'utils/app'

const ResendConfirmationEmailForm: React.FC = () => {
  const { t } = useTranslation('credentialApplicationSignIn')

  const [email, setEmail] = useState('')
  const [isResendRegisterEmailDisabled, setResendRegisterEmailDisabled] = useState(false)

  const app = getAppDataFromStorage()

  const [resendRegisterEmail, resendRegisterEmailResp] = useResendRegisterEmail({
    onCompleted() {
      message.success(
        t('resendEmailSuccessText', {
          email,
        })
      )

      setResendRegisterEmailDisabled(true)
    },
    onError(error) {
      message.error(error.message)

      setResendRegisterEmailDisabled(true)
    },
  })

  return (
    <EmailForm
      loading={resendRegisterEmailResp.loading}
      disabled={isResendRegisterEmailDisabled}
      buttonName="resendEmail"
      onFinish={(values) => {
        const { email } = values

        setEmail(email)
        resendRegisterEmail({
          variables: {
            email,
          },
          context: {
            headers: {
              credentialKey: app?.credential?.credentialKey,
            },
          },
        })
      }}
    />
  )
}

export default ResendConfirmationEmailForm
