import React, { useEffect, useState } from 'react'
import { message, Upload as AntdUpload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import styled from 'styled-components'

import { s3Pathname } from 'config'

import useGetS3PutObjectSignedUrl from 'graphQL/useGetS3PutObjectSignedUrl'

import ImagePreviewModal from 'components/ImagePreviewModal'
import FileList from './FileList'

import type { UploadImageProps } from './interface'
import type { RcFile } from 'antd/lib/upload'
import type { S3PutObjectAPIPayload } from 'graphQL/useGetS3PutObjectSignedUrl/interface'

const Upload: React.FC<UploadImageProps> = ({
  aclType,
  isEdit,
  allowFileExtensions,
  maximumUploadItems,
  value,
  onChange,
}) => {
  const [file, setFile] = useState<RcFile | undefined>()
  const [previewImageModal, setPreviewImageModal] = useState(false)
  const [isImageUploading, setImageUploading] = useState(false)
  const [uploadImage, setUploadImage] = useState<S3PutObjectAPIPayload | undefined>()
  const [previewImageSource, setPreviewImageSource] = useState('')

  const isReachMaximumUploadItems = value && maximumUploadItems && value.length >= maximumUploadItems
  const shouldDisplayUploadButton = !isReachMaximumUploadItems

  const aclTypeFallback = aclType || 'PUBLIC'

  const s3PutObjectQuery = useGetS3PutObjectSignedUrl({
    skip: !file,
    variables: {
      userId: '',
      inputs: [
        {
          acl: aclTypeFallback || 'PUBLIC',
          contentType: file?.type,
          objectName: file?.name,
          path: s3Pathname,
        },
      ],
    },
    notifyOnNetworkStatusChange: true,
    onCompleted(resp) {
      const imageData = resp.getS3PutObjectSignedUrl.payload[0]

      console.log('imageData', imageData);

      setUploadImage(imageData)
    },
    onError(error) {
      message.error('เกิดข้อผิดพลาดระหว่างระอัพโหลด โปรดลองใหม่อีกครั้ง')

      setFile(undefined)
      setImageUploading(false)
    },
  })

  useEffect(() => {
    const doUploadImage = async () => {
      const headers = {
        'content-type': file?.type,
        'x-amz-acl': aclTypeFallback === 'PUBLIC' ? 'public-read' : 'private',
      }

      try {
        await axios.put(String(uploadImage?.signedUrl), file, {
          headers,
        })

        onUploadSuccess()

        onChange?.((value || []).concat([String(process.env.REACT_APP_S3_BUCKET_NAME+"/"+uploadImage?.fileKey)]))
      } catch (error) {
        message.error('เกิดข้อผิดพลาดระหว่างการอัพโหลด')
      } finally {
        setImageUploading(false)
      }
    }

    if (file && uploadImage) {
      doUploadImage()
    }
  }, [file, value, aclType, aclTypeFallback, uploadImage, onChange])

  const isLoading = s3PutObjectQuery.loading || isImageUploading

  return (
    <>
      <FileList
        aclType={aclType}
        isEdit={isEdit}
        fileList={value}
        onPreview={(src) => {
          console.log('src', src);
          setPreviewImageModal(true)
          setPreviewImageSource(src)
        }}
        onRemove={(index) => {
          const removeImageByIndex = Array.isArray(value)
            ? value?.filter((_, imagePosition) => imagePosition !== index)
            : []

          onChange?.(removeImageByIndex)
        }}
      >
        <AntdUpload
          accept={allowFileExtensions}
          showUploadList={false}
          beforeUpload={(file) => {
            setImageUploading(true)
            setFile(file)

            return false
          }}
        >
          {shouldDisplayUploadButton && (
            <UploadContainer>
              {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>อัพโหลด</div>
            </UploadContainer>
          )}
        </AntdUpload>
      </FileList>

      <ImagePreviewModal
        minWidth={520}
        visible={previewImageModal}
        src={previewImageSource}
        onCancel={() => {
          setPreviewImageModal(false)
          setPreviewImageSource('')
        }}
      />
    </>
  )

  function onUploadSuccess() {
    setFile(undefined)
    setUploadImage(undefined)
    setImageUploading(false)
  }
}

export default Upload

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #999;
  max-width: 150px;
  min-width: 150px;
  max-height: 150px;
  min-height: 150px;
  cursor: pointer;
  transition: 0.15s all ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`
