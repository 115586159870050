import React, { FC } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'

import useAuth from 'contexts/useAuthContext'

import withCredential from 'middlewares/withCredential'

import useQueryString from 'hooks/useQueryString'

import SignInPage from 'pages/signIn'
import SignOutPage from 'pages/signOut'
import ApplicationSignInPage from 'pages/applicationSignIn'
import ResetPasswordPage from 'pages/resetPassword'
import VerifyEmailPage from 'pages/verifyEmail'
import VerifyInviteOrganizationPage from 'pages/verifyInviteOrganization'

import GeneralPage from 'pages/general'
import SecurityPage from 'pages/security'
import SettingPage from 'pages/setting'
import NotFoundPage from 'pages/notFound'
import AuthPage from 'pages/AuthPage'

import RegisterPage from 'pages/register'
import CredentialApplicationSignInPage from 'pages/credentialApplicationSignIn'
import LiffPage from 'pages/liff/LiffPage'

import type { GetMyProfileData } from 'graphQL/queryResponseTypes'
import type { RoutePath } from './types'

export const paths: RoutePath = {
  root: '/',
  signIn: '/sign-in',
  register: '/register',
  resetPassword: '/reset-password',
  resendEmail: '/resend-email',
  verifyEmail: '/verify-email',
  verifyInvitationOrganization: '/verify-invite-organization',
  applicationSignIn: '/application-sign-in',
  credentialApplicationSignIn: '/credential-application-sign-in',
  general: '/general',
  security: '/security',
  setting: '/setting',
  liff: '/liff',
  auth: '/auth',
  signOut: '/sign-out',
}

const PageRouter: React.FC = () => {
  const { user } = useAuth()

  return (
    <BrowserRouter>
      <Routes>
        <Route path={paths.root} element={<NavigateToDestinationRoute user={user} />} />

        <Route path={paths.verifyEmail} element={<VerifyEmailPage />} />
        <Route path={paths.resetPassword} element={<ResetPasswordPage />} />
        <Route path={paths.verifyInvitationOrganization} element={<VerifyInviteOrganizationPage />} />
        <Route path={paths.auth} element={<AuthPage />} />

        <Route element={<SignInRoute user={user} />}>
          <Route path={paths.signIn} element={<SignInPage />} />
          <Route path={paths.applicationSignIn} element={<ApplicationSignInPage />} />
        </Route>

        <Route element={<SignInWithCredentialRoute />}>
          <Route path={paths.register} element={<RegisterPage />} />
          <Route path={paths.credentialApplicationSignIn} element={<CredentialApplicationSignInPage />} />
          <Route path={paths.liff} element={<LiffPage />} />
        </Route>

        <Route element={<AuthenticatedRoute user={user} />}>
          <Route path={paths.general} element={<GeneralPage />} />
          <Route path={paths.security} element={<SecurityPage />} />
          <Route path={paths.setting} element={<SettingPage />} />
          <Route path={paths.signOut} element={<SignOutPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default PageRouter

interface AuthenticatedUserRouteProps {
  user?: GetMyProfileData
}

const NavigateToDestinationRoute: FC<AuthenticatedUserRouteProps> = ({ user }) => {
  const query = useQueryString()

  const redirectTo = user == null ? paths.credentialApplicationSignIn : paths.general

  return <Navigate to={`${redirectTo}?${query.toString()}`} replace />
}

const SignInRoute: React.FC<AuthenticatedUserRouteProps> = ({ user }) => {
  if (user != null) {
    return <NavigateToDestinationRoute user={user} />
  }

  return <Outlet />
}

const AuthenticatedRoute: React.FC<AuthenticatedUserRouteProps> = ({ user }) => {
  if (user == null) {
    return <NavigateToDestinationRoute user={user} />
  }

  return <Outlet />
}

const SignInWithCredentialRoute: React.FC = withCredential(() => {
  return <Outlet />
})
