import React from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

import SystemFormInput from 'components/SystemFormInput'

import Upload from 'components/Upload'

import { allowFileExtensions } from 'config'

import type { SystemFormProps } from './interface'

const SystemForm: React.FC<SystemFormProps> = ({ namePrefixList }) => {
  const { t } = useTranslation('systemForm')

  return (
    <>
      <Form.Item name="profilePictureFileKey" label={t('label.profilePictureFileKey.title')}>
        <Upload maximumUploadItems={1} isEdit allowFileExtensions={allowFileExtensions} />
      </Form.Item>

      <SystemFormInput namePrefixList={namePrefixList} isEmailDisabled />
    </>
  )
}

export default SystemForm
