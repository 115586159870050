import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useGetMasterData from 'graphQL/useGetMasterData'

import { groupMasterData } from 'utils/masterData'
import { localeCode } from 'utils/localService'

import type { MasterDataAPIPayload } from 'graphQL/useGetMasterData/interface'
import type { ServiceHook } from 'hooks/interface'
import type { MasterDataList, UseMasterDataOptions, UseMasterDataResponse } from './interface'

const useMasterData: ServiceHook<UseMasterDataResponse, UseMasterDataOptions> = (options) => {
  const { i18n } = useTranslation()

  const [masterDataList, setMasterDataList] = useState<MasterDataList>({})
  const [loading, setLoading] = useState(true)

  const onRequestMasterData = (payload: MasterDataAPIPayload[]) => {
    let masterData: MasterDataList = {}

    options?.parentKeys.forEach((parentKey) => {
      masterData = {
        ...masterData,
        ...groupMasterData(payload, parentKey),
      }
    })

    setMasterDataList(masterData)
    setLoading(false)
  }

  const masterDataQuery = useGetMasterData({
    variables: {
      input: {
        query: {
          parentKey: { $in: options?.parentKeys },
          locale: i18n.language === localeCode.thTH ? 'th' : 'en',
        },
        sort: {
          ...options?.sortCondition,
        },
      },
    },
    onCompleted(resp) {
      onRequestMasterData(resp.getMasterData.payload)
    },
  })

  return {
    masterDataList,
    loading: loading || masterDataQuery.loading,
  }
}

export default useMasterData
