import { useMutation } from '@apollo/client'
import { message } from 'antd'

import { getEndpointByCredentialKeyType } from 'helpers/utils'

import MUTATION_DOCUMENT from './changePassword'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { MyProfileAPIPayload } from 'graphQL/useGetMyProfile/interface'

interface ChangePasswordData {
  changePassword: APIPayloadResponse<MyProfileAPIPayload>
}

interface ChangePasswordVars {
  password: string
  newPassword: string
  confirmPassword: string
}

const useChangePassword: GraphQLServiceMutationHook<ChangePasswordData, ChangePasswordVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    context: {
      uri: getEndpointByCredentialKeyType(),
    },
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useChangePassword
