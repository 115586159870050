import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import DefaultLayout from 'layouts/DefaultLayout'

import useRegisterEmail from 'graphQL/useRegisterEmail'
import useUpdateMyProfile from 'graphQL/useUpdateMyProfile'
import useGetMyProfile from 'graphQL/useGetMyProfile'

import { authorizationKey, queryKey, thirdPartyChannelType } from 'config'

import { getEndpointByCredentialKeyType } from 'helpers/utils'

import useRole from 'hooks/useRole'
import useQueryString from 'hooks/useQueryString'
import useCheckFeatureKeyListEnable from 'hooks/useCheckFeatureKeyListEnable'

import RoleSelection from 'components/RoleSelection'
import RegisterForm from './RegisterForm'

import { paths } from 'setup/PageRouter'

import type { RegisterProps } from './interface'

const emailAlreadyExistNotVerify = 'EMAIL_ALREADY_EXIST_NOT_VERIFY'

const Register: React.FC<RegisterProps> = ({ app }) => {
  const { t } = useTranslation('platform')
  const navigate = useNavigate()

  const [roleKey, setRoleKey] = useState<string | undefined>()

  const query = useQueryString()
  const code = query.get(authorizationKey.code) as string | undefined
  const roleKeyQuery = query.get('role_key')
  const credentialKey = query.get(authorizationKey.credential)
  const channelType = query.get(queryKey.channelType)

  const fromThirdPartySignIn = query.get('fromThirdPartySignIn') === 'true'
  const isFromLiffLogin = channelType && thirdPartyChannelType.includes(channelType)

  const checkFeatureKeyEnable = useCheckFeatureKeyListEnable({
    featureKeyList: ['EMAIL_VERIFICATION'],
  })

  const role = useRole({
    credentialKey,
    roleKey,
  })

  const myProfileResp = useGetMyProfile({
    skip: !fromThirdPartySignIn,
  })

  const [registerEmail, registerEmailResp] = useRegisterEmail({
    onCompleted() {
      const whenHaveCodeShouldNotToVerify = checkFeatureKeyEnable.EMAIL_VERIFICATION && code != null

      const successText =
        whenHaveCodeShouldNotToVerify || !checkFeatureKeyEnable.EMAIL_VERIFICATION
          ? 'registerSuccessText'
          : 'registerWithMustVerifySuccessText'

      message.success(t(`register:${successText}`))

      navigate({
        pathname: paths.credentialApplicationSignIn,
        search: query.toString(),
      })
    },
    onError(error) {
      const graphqlError = error.graphQLErrors[0]

      if (graphqlError.extensions?.code !== emailAlreadyExistNotVerify) {
        message.error(error.message)
      } else {
        message.success(error.message)

        navigate({
          pathname: paths.credentialApplicationSignIn,
          search: window.location.search,
        })
      }
    },
  })

  const [updateMyProfile, updateMyProfileResp] = useUpdateMyProfile({
    onCompleted() {
      message.success(t('register:thirdPartyRegisterSuccessText'))

      if (isFromLiffLogin) {
        navigate({
          pathname: paths.liff,
          search: window.location.search,
        })
      } else {
        navigate({
          pathname: paths.credentialApplicationSignIn,
          search: window.location.search,
        })
      }
    },
  })

  const myProfileData = myProfileResp.data?.getMyProfile.payload

  return (
    <DefaultLayout loading={role.loading}>
      <RegisterForm
        key={roleKey}
        profileData={myProfileData}
        loading={registerEmailResp.loading || updateMyProfileResp.loading}
        dataKeyList={role.dataKeyList}
        fieldList={role.userFields}
        onFinish={(data) => {
          const { email, password, confirmPassword, ...attribute } = data

          const userAttribute = {
            ...attribute,
            dateOfBirth: dayjs(data.dateOfBirth).toISOString(),
          }

          const context = {
            uri: getEndpointByCredentialKeyType(),
            headers: {
              credentialKey: app?.credential.credentialKey,
            },
          }

          if (isFromLiffLogin || fromThirdPartySignIn) {
            updateMyProfile({
              variables: {
                input: {
                  attribute: userAttribute,
                },
              },
              context,
            })
          } else {
            registerEmail({
              variables: {
                input: {
                  email,
                  password,
                  confirmPassword,
                  attribute: userAttribute,
                  roleKey: roleKeyQuery || '',
                  inviteCode: code,
                },
              },
              context,
            })
          }
        }}
      />
      {role.roleList.length > 1 && <RoleSelection {...role} onSelect={(value) => setRoleKey(value)} />}
    </DefaultLayout>
  )
}

export default Register
