import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { message } from 'antd'

import type {
  APIPayloadResponse,
  AuthType,
  GraphQLServiceMutationHook,
  LoginAPIPayload,
} from 'graphQL/graphQL-service-hook'

const MUTATION_DOCUMENT = loader('./loginGoogle.gql')

interface LoginGoogleData {
  loginGoogle: APIPayloadResponse<LoginAPIPayload>
}

interface LoginGoogleVars {
  googleToken: string
  authType: AuthType
  redirectUrl?: string | null
}

const useLoginGoogle: GraphQLServiceMutationHook<LoginGoogleData, LoginGoogleVars> = (options) =>
  useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })

export default useLoginGoogle
