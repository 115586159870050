import React from 'react'
import { Button, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import useMasterData from 'hooks/useMasterData'
import useQueryString from 'hooks/useQueryString'

import FullWidthSpace from 'components/FullWidthSpace'
import LoadingIcon from 'components/LoadingIcon'
import GeneralFormInput from 'components/GeneralFormInput'
import DefaultRegisterFormInput from './DefaultRegisterFormInput'

import type { UserField } from 'graphQL/useGetRoleRegistable/interface'
import type { MyProfileAPIPayload } from 'graphQL/useGetMyProfile/interface'

interface RegisterFormProps {
  loading?: boolean
  profileData?: MyProfileAPIPayload
  dataKeyList: string[]
  fieldList: UserField[]
  onFinish: (data: any) => void
}

const RegisterForm: React.FC<RegisterFormProps> = ({ loading, profileData, dataKeyList, fieldList, onFinish }) => {
  const { t } = useTranslation('register')
  const query = useQueryString()
  const email = query.get('email')

  const shouldDisabledEmailInput = profileData != null

  const formUserEmail = profileData == null ? email : profileData.email[0]?.value

  const masterData = useMasterData({
    parentKeys: [...dataKeyList, 'DIALING_CODE', 'NAME_PREFIX'],
    sortCondition: {"attribute.order": 1}
  })

  if (masterData.loading) {
    return <LoadingIcon />
  }

  return (
    <Form
      name="registerForm"
      layout="vertical"
      labelAlign="left"
      onFinish={onFinish}
      initialValues={{
        ...profileData?.attribute,
        email: formUserEmail,
      }}
    >
      <FullWidthSpace size={16} direction="vertical">
        <DefaultRegisterFormInput
          disabledEmailInput={shouldDisabledEmailInput}
          namePrefix={masterData.masterDataList.NAME_PREFIX}
          email={email}
        />

        <GeneralFormInput fieldList={fieldList} masterDataList={masterData.masterDataList} />

        <Form.Item>
          <Button block loading={loading} type="primary" htmlType="submit">
            {t('button')}
          </Button>
        </Form.Item>
      </FullWidthSpace>
    </Form>
  )
}

export default RegisterForm
