import { useQuery } from '@apollo/client'

import QUERY_DOCUMENT from './getAppTheme'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { AppThemeAPIPayload } from './interface'

interface AppThemeData {
  getAppTheme: APIPayloadResponse<AppThemeAPIPayload[]>
}

interface AppThemeVars {
  find?: FindDataInput
}

const useGetAppTheme: GraphQLServiceQueryHook<AppThemeData, AppThemeVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      console.log(error)
    },
    ...options,
  })
}

export default useGetAppTheme
