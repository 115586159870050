import React, { useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Result } from 'antd'
import { useTranslation } from 'react-i18next'

import DefaultLayout from 'layouts/DefaultLayout'

import useVerifyPasswordEmail from 'graphQL/useVerifyPasswordEmail'

import { paths } from 'setup/PageRouter'

import ResetPasswordForm from './ResetPasswordForm'

const ResetPassword: React.FC = () => {
  const { t } = useTranslation('verifyPasswordEmail')

  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')
  const verifyToken = searchParams.get('verifyToken')

  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false)

  const [verifyPasswordEmail, verifyPasswordEmailResp] = useVerifyPasswordEmail({
    onCompleted() {
      setIsResetPasswordSuccess(true)
    },
  })

  if (email == null || verifyToken == null) {
    return <Navigate to={paths.root} />
  }

  return (
    <DefaultLayout>
      {isResetPasswordSuccess ? (
        <Result status="success" title={t('successTitle')} />
      ) : (
        <ResetPasswordForm
          loading={verifyPasswordEmailResp.loading}
          onFinish={(data) => {
            verifyPasswordEmail({
              variables: {
                input: {
                  email,
                  verifyToken,
                  ...data,
                },
              },
            })
          }}
        />
      )}
    </DefaultLayout>
  )
}

export default ResetPassword
