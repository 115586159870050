import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './deleteMyUser'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

interface DeleteMyUserData {
  deleteMyUser: APIPayloadResponse<{ _id: string }>
}

const useDeleteMyUser: GraphQLServiceMutationHook<DeleteMyUserData> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useDeleteMyUser
