import React, { useState } from 'react'
import { Button, Divider, Form, Input } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import FontawesomeIcon from 'components/FontAwesomeIcon'
import FullWidthSpace from 'components/FullWidthSpace'
import GoogleIcon from 'components/GoogleIcon'
import FacebookIcon from 'components/FacebookIcon'

import { authorizationKey, facebookAppID, googleClientID } from 'config'

import useAuth from 'contexts/useAuthContext'

import useQueryString from 'hooks/useQueryString'
import useCheckFeatureKeyListEnable from 'hooks/useCheckFeatureKeyListEnable'

import useLoginGoogle from 'graphQL/useLoginGoogle'
import useLoginFacebook from 'graphQL/useLoginFacebook'
import useUpdateMyProfile from 'graphQL/useUpdateMyProfile'

import { paths } from 'setup/PageRouter'

import type { ReactFacebookLoginInfo } from 'react-facebook-login'
import type { CredentialApplicationSignInCardProps, FacebookCustomButtonRenderProps, InputProps } from './interface'
import type { ProfileAttribute } from 'graphQL/useGetMyProfile/interface'
import type { LoginAPIPayload } from 'graphQL/graphQL-service-hook'

const CredentialApplicationSignInCard: React.FC<CredentialApplicationSignInCardProps> = ({
  buttonLoading,
  onFinish,
  onSignInPanelTypeChange,
}) => {
  const { t } = useTranslation('credentialApplicationSignIn')

  const auth = useAuth()

  const navigate = useNavigate()

  const query = useQueryString()
  const redirectUrl = query.get('redirect_url')

  const checkFeatureKeyEnable = useCheckFeatureKeyListEnable({
    featureKeyList: ['FACEBOOK_AUTHENTICATION', 'GOOGLE_AUTHENTICATION', 'CLOSE_REGISTER'],
  })

  const hideAllThirdPartySignInButtons =
    !checkFeatureKeyEnable.FACEBOOK_AUTHENTICATION && !checkFeatureKeyEnable.GOOGLE_AUTHENTICATION

  const [user, setUser] = useState<ProfileAttribute | undefined>()

  const [updateMyProfile, updateMyProfileResp] = useUpdateMyProfile()

  const [loginGoogle, loginGoogleResp] = useLoginGoogle({
    onCompleted(resp) {
      onSignIn(resp.loginGoogle.payload)
    },
  })
  const [loginFacebook, loginFacebookResp] = useLoginFacebook({
    onCompleted(resp) {
      onSignIn(resp.loginFacebook.payload)
    },
  })

  const { signIn, loaded } = useGoogleLogin({
    clientId: googleClientID,
    onSuccess: onSignInWithGoogle,
    onFailure: (response) => console.log('Failure logged in', response),
  })

  const inputList: InputProps[] = [
    {
      icon: 'user',
      type: 'email',
      placeholder: t('global:inputPlaceholder', {
        placeholderName: t('label.email'),
      }),
    },
    {
      icon: 'key',
      type: 'password',
      placeholder: t('global:inputPlaceholder', {
        placeholderName: t('label.password'),
      }),
    },
  ]

  return (
    <CredentialApplicationSignInCardWrapper>
      <Form style={{ width: '100%' }} onFinish={onFinish}>
        <Form.Item>
          <SignInTitle className="bold">{t('title')}</SignInTitle>
        </Form.Item>

        {inputList.map((input, index) => renderCredentialSignInFormInput(input, index))}

        <Form.Item>
          <SignInHelperMenuContainer>
            {/* <HelperTitle onClick={() => onSignInPanelTypeChange('ResendEmail')}>
              {t('resendConfirmationEmailText')}
            </HelperTitle>
            <span style={{ color: '#999' }}>{t('global:or')}</span> */}
            <span style={{ color: '#999' }}>{t('cannotLoginForgotPassword')}</span>
            <HelperTitle onClick={() => onSignInPanelTypeChange('ResetPassword')}>
              {t('forgotPasswordTitle')}
            </HelperTitle>
          </SignInHelperMenuContainer>
        </Form.Item>

        {!checkFeatureKeyEnable.CLOSE_REGISTER && (
          <div style={{ marginBottom: 32 }}>
            <span>{t('registerTitle')}</span>
            <RegisterLink
              to={{
                pathname: paths.register,
                search: window.location.search,
              }}
            >
              {t('button.register')}
            </RegisterLink>
          </div>
        )}

        <Form.Item>
          <Button block loading={buttonLoading} type="primary" htmlType="submit">
            {t('button.signIn')}
          </Button>
        </Form.Item>
      </Form>

      {!hideAllThirdPartySignInButtons && (
        <>
          <Divider>หรือ</Divider>

          <FullWidthSpace direction="vertical" size="middle">
            <Button
              hidden={!checkFeatureKeyEnable.GOOGLE_AUTHENTICATION}
              loading={!loaded || loginGoogleResp.loading || updateMyProfileResp.loading}
              block
              icon={<GoogleIcon />}
              onClick={signIn}
            >
              Sign in with Google
            </Button>

            <FacebookLogin appId={facebookAppID} callback={onSignInWithFacebook} render={renderCustomFacebookButton} />
          </FullWidthSpace>
        </>
      )}
    </CredentialApplicationSignInCardWrapper>
  )

  function renderCredentialSignInFormInput(input: InputProps, index: number) {
    const { icon, ...rest } = input

    let inputRender = <Input {...rest} type="text" prefix={<FontawesomeIcon iconName={icon} />} />

    if (rest.type === 'password') {
      inputRender = (
        <Input.Password {...rest} autoComplete="sign-in-password" prefix={<FontawesomeIcon iconName={icon} />} />
      )
    }

    return (
      <Form.Item name={rest.type} key={index} rules={[{ required: true, message: t('global:requiredText') }]}>
        {inputRender}
      </Form.Item>
    )
  }

  function onSignInWithFacebook(facebookResponse: ReactFacebookLoginInfo) {
    const { accessToken, name } = facebookResponse

    if (accessToken) {
      const [firstName, lastName] = name?.split(' ') || ['', '']

      setUser({
        firstName,
        lastName,
      })

      loginFacebook({
        variables: {
          redirectUrl,
          authType: 'AUTH_CODE',
          facebookToken: accessToken,
        },
      })
    }
  }

  function onSignInWithGoogle(googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline) {
    const { accessToken, profileObj } = googleResponse as GoogleLoginResponse

    if (accessToken) {
      const { givenName, familyName } = profileObj

      setUser({
        firstName: givenName,
        lastName: familyName,
      })

      loginGoogle({
        variables: {
          redirectUrl,
          authType: 'AUTH_CODE',
          googleToken: accessToken,
        },
      })
    }
  }

  function onUpdateMyProfile() {
    updateMyProfile({
      variables: {
        input: {
          attribute: user,
        },
      },
    })
  }

  function onSignIn(loginPayload: LoginAPIPayload) {
    const { token, authType, isFirstLogin, credentialKey, code, redirectUrl } = loginPayload

    auth.signIn(token)

    query.set('fromThirdPartySignIn', 'true')

    if (isFirstLogin) {
      navigate({
        pathname: paths.register,
        search: `?${query.toString()}`,
      })
    } else {
      if (redirectUrl) {
        const callbackUrl = `${redirectUrl}?${authorizationKey.code}=${code}&${authorizationKey.authType}=${authType}&${authorizationKey.credential}=${credentialKey}`

        window.open(callbackUrl, '_self')
      } else {
        navigate(paths.general)
      }
    }

    onUpdateMyProfile()
  }

  function renderCustomFacebookButton({
    isDisabled,
    isSdkLoaded,
    isProcessing,
    onClick,
  }: FacebookCustomButtonRenderProps) {
    const loading = !isSdkLoaded || isProcessing || loginFacebookResp.loading || updateMyProfileResp.loading

    return (
      <Button
        hidden={!checkFeatureKeyEnable.FACEBOOK_AUTHENTICATION}
        block
        icon={<FacebookIcon />}
        disabled={isDisabled}
        loading={loading}
        onClick={onClick}
      >
        Sign in with Facebook
      </Button>
    )
  }
}

export default CredentialApplicationSignInCard

const CredentialApplicationSignInCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
`

const SignInTitle = styled.span`
  font-size: 35px;
`

const RegisterLink = styled(Link)`
  margin-left: 16px;
  color: var(--app-primary);
  cursor: pointer;
  text-decoration: underline;
`

const HelperTitle = styled.span`
  color: var(--app-primary);
  cursor: pointer;
`

const SignInHelperMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
