import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

import FullScreenLoading from 'components/FullScreenLoading'

import useAuth from 'contexts/useAuthContext'

const SignOutPage: React.FC = () => {
  const { t } = useTranslation('loading')

  const auth = useAuth()

  useEffectOnce(() => {
    auth.signOut()
  })

  return <FullScreenLoading>{t('signOut')}</FullScreenLoading>
}

export default SignOutPage
