import { gql } from '@apollo/client'

const RESEND_REGISTER_EMAIL = gql`
  mutation resendRegisterEmail($email: String!) {
    resendRegisterEmail(email: $email) {
      payload {
        email {
          value
        }
      }
    }
  }
`

export default RESEND_REGISTER_EMAIL
