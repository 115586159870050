import { ParsedQuery, stringify } from 'query-string'
import { generatePath, Params } from 'react-router-dom'

import apiPath from 'config/api'

import { getAppDataFromStorage } from 'utils/app'

interface RouteToOptions {
  params?: Params<string>
  query?: ParsedQuery
}

export const getEndpointByCredentialKeyType = (): string => {
  const app = getAppDataFromStorage()

  if (app?.credential == null) {
    return apiPath.core.client
  }

  const { type } = app.credential

  switch (type) {
    case 'SYSTEM_ADMIN':
      return apiPath.core.systemAdmin
    case 'RESOURCE_ADMIN':
      return apiPath.core.admin
    case 'RESOURCE_OWNER':
      return apiPath.core.client
  }
}

export const routerTo = (route: string, options: RouteToOptions) => {
  const qs = options.query == null ? undefined : `?${stringify(options.query)}`

  return `${generatePath(route)}${qs}`
}
