import React from 'react'
import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import useAuth from 'contexts/useAuthContext'

import ContentCard from 'components/ContentCard'
import PageTitle from 'components/PageTitle'
import StickAtBottom from 'components/StickAtBottom'
import HooraySubmitButton from 'components/HooraySubmitButton'
import ActionRow from 'components/ActionRow'
import SystemForm from './SystemForm'
import GeneralForm from './GeneralForm'

import useMasterData from 'hooks/useMasterData'
import useQueryString from 'hooks/useQueryString'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import { useUpdateMyProfileMutation } from 'graphQL/generated/operations'

import { authorizationKey } from 'config'
import { commonFormProps } from 'utils/antd'

import type { GeneralProps } from './interface'

const General: React.FC<GeneralProps> = ({ dataKeyList, fieldList }) => {
  const { t } = useTranslation('general')

  const auth = useAuth()

  const query = useQueryString()
  const redirectUrl = query.get(authorizationKey.redirectUrl)

  const { loading, masterDataList } = useMasterData({
    parentKeys: [...dataKeyList, 'DIALING_CODE', 'NAME_PREFIX'],
    sortCondition: {"attribute.order": 1}
  })

  const [updateMyProfile, updateMyProfileResp] = useUpdateMyProfileMutation({
    onCompleted(resp) {
      message.success(t('updateProfileSuccessText'))

      auth.setUser(resp.updateMyProfile.payload)

      if (redirectUrl) {
        setTimeout(() => {
          window.open(redirectUrl, '_self')
        }, 350)
      }
    },
  })

  return (
    <AuthenticatedLayout>
      <PageTitle isRootPage title={t('title')} />

      <Form
        {...commonFormProps}
        initialValues={{
          ...auth.user?.attribute,
          dateOfBirth: dayjs(auth.user?.attribute?.dateOfBirth),
          email: auth.user?.email[0].value,
        }}
        labelAlign="left"
        onFinish={(data) => {
          const { email, ...attribute } = data

          updateMyProfile({
            variables: {
              input: {
                attribute,
              },
            },
          })
        }}
      >
        <ContentCard title={t('cardTitle.system')} loading={loading}>
          {!loading && <SystemForm namePrefixList={masterDataList.NAME_PREFIX} />}
        </ContentCard>

        {fieldList.length > 0 && (
          <ContentCard title={t('cardTitle.general')} loading={loading}>
            {!loading && <GeneralForm fieldList={fieldList} masterDataList={masterDataList} />}
          </ContentCard>
        )}

        <StickAtBottom>
          <ActionRow rightContent={<HooraySubmitButton loading={updateMyProfileResp.loading} />} />
        </StickAtBottom>
      </Form>
    </AuthenticatedLayout>
  )
}

export default General
